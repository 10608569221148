import { UserDetailsService, SetTitleService, SnackbarService } from "@commons/services";
import { HttpClient } from "@angular/common/http";
import { PasswordChangePostRequest } from "@entities/api/password-reset.interfaces";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Redirect, httpCodes, HttpService } from "@ha8rt/http.service";
import { ModalHandler } from "@ha8rt/modal";
import { szegedswLogo, paths, routes } from "@config";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  logo = szegedswLogo;
  service: HttpService;
  modal = new ModalHandler();
  redirect: Redirect;
  hideOldPassword = true;
  hideNewPassword = true;
  hideNewPasswordAgain = true;
  pwMatch: { oldPw: string; newPw: string; match: boolean } = {
    oldPw: "",
    newPw: "",
    match: false,
  };

  constructor(
    private router: Router,
    private snackbar: SnackbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private title: SetTitleService
  ) {
    this.redirect = new Redirect(this.router, this.route);
    this.service = new HttpService(this.http).setApi(routes.passwordChange);
  }

  ngOnInit() {
    this.title.setTitle("Change password");
  }

  changePasswordSubmit(form: NgForm) {
    const postRequest: PasswordChangePostRequest = {
      username: form.value.username,
      oldPassword: form.value.oldPassword,
      newPassword: form.value.newPassword,
    };
    this.service.post<unknown>(postRequest, (res) => {
      if (res.status === httpCodes.created) {
        this.snackbar.openAlertMsg(
          `Password change successful! You can log in now.`,
          "lightgreen",
          6000,
        );
        UserDetailsService.forgottenPassword = false;
        this.router.navigate([paths.login]);
        this.redirect.setDefaultUrl(paths.login);
        localStorage.removeItem("urlAfterRedirects");
      } else if (res.status === httpCodes.notFound) {
        this.snackbar.openAlertMsg(
          `Wrong username or password!`,
          "red",
          5000,
        );
      }
    });
  }

  goToLogin() {
    UserDetailsService.forgottenPassword = false;
    this.router.navigate([paths.login]);
    this.redirect.setDefaultUrl(paths.login);
    localStorage.removeItem("urlAfterRedirects");
  }

  passwordsMatch() {
    if (this.pwMatch.oldPw === this.pwMatch.newPw && this.pwMatch.oldPw !== "") {
      this.pwMatch.match = true;
    } else {
      this.pwMatch.match = false;
    }
  }
}
