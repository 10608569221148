import { UserDetailsService } from "../services/user-details/user-details.service";

export async function hasPermission(permissionName: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let helper = 0;
    if (UserDetailsService.permissionsForUser) {
      Promise.all(UserDetailsService.permissionsForUser.map((perm) => {
        if (perm.permission === permissionName) {
          helper += 1;
        }
      }));
    }
    helper > 0 ? resolve(true) : resolve(false);
  });
}
