import { UserDetailsService } from "@commons/services/user-details/user-details.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, Inject, OnInit } from "@angular/core";
import { DictionaryEntry } from "@commons/functions/dictionary.query";
import { UserlistGetResponse } from "@entities/api/userlist.interfaces";
import { dictionaryQuery } from "@commons/functions/dictionary.query";
import { HttpService } from "@ha8rt/http.service";
import { routes } from "@config";
import { HttpClient } from "@angular/common/http";

const workTypeGroupName = "notices-work_type";
const noticeTypeGroupName = "notices-notice_type";
export interface IQuickNoteDialogData {
  header?: string;
  demandLabel?: string;
  content?: string;
  noticeText?: string;
  submitted?: number;
  onlyDelete?: boolean;
  selectedNoticeFile?: FileList;
  workType?: string;
  publicNotice?: boolean;
  answerer?: string;
  noticeType?: string;
}

@Component({
  selector: "app-quick-note-dialog",
  templateUrl: "./quick-note-dialog.component.html",
  styleUrls: ["./quick-note-dialog.component.scss"]
})
export class QuickNoteDialogComponent implements OnInit {
  static readonly textColor = "black";
  static readonly buttonTextColor = "white";
  static readonly buttonBgColor = "#10498c";
  modules = {};
  onlyDel: boolean;
  selectedNoticeFile: FileList;
  displayFiles = "";
  workTypes: DictionaryEntry[] = [];
  noticeTypes: DictionaryEntry[] = [];
  userList: UserlistGetResponse;
  dictionaryQueryService: HttpService;
  userListService: HttpService;

  constructor(
    public dialogRef: MatDialogRef<QuickNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IQuickNoteDialogData,
    private http: HttpClient
  ) {
    this.dictionaryQueryService = new HttpService(this.http).setApi(routes.dictionaryQuery);
    this.userListService = new HttpService(this.http).setApi(routes.userlist);
    this.userListService.get<UserlistGetResponse>({szegedswWorker: true, active: true}, (res) => {
      this.userList = res.body;
    });
    this.onlyDel = this.data.onlyDelete || false;
    this.modules = {
      toolbar: [
        ["bold", "italic", "underline", "clean"],
      ],
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler(range, context) {
              return true;
            },
          },
          "list autofill": {
            prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/
          }
        }
      }
    };
  }
  ngOnInit() {
    dictionaryQuery(workTypeGroupName, this.workTypes, this.dictionaryQueryService);
    dictionaryQuery(noticeTypeGroupName, this.noticeTypes, this.dictionaryQueryService);
    this.data.workType = UserDetailsService.defaultWorktype;
    this.data.noticeType = "Comment";
  }

  buttonEvent(submitted: number) {
    this.data.submitted = submitted;
    if (submitted === 0) {
      this.dialogRef.close();
    }
  }

  selectFile(event) {
    this.selectedNoticeFile = event.target.files;
    this.displayFiles = "";
    for (let i = 0; i < this.selectedNoticeFile.length; i++) {
      if (i !== 0) {
        this.displayFiles += `\n`;
      }
      this.displayFiles += `${this.selectedNoticeFile.item(i).name}`;
    }
  }
}
