export const paths = {
  home: "",
  reload: "reload",
  notFound: "notFound",
  notImplementedYet: "notImplementedYet",
  settings: "settings",
  search: "search",
  login: "login",
  forgottenPassword: "forgottenPassword",
  changePassword: "changePassword",
  bookmarks: "bookmarks",
  translations: "translations",

  clientOrganization: {
    rootPath: "clientOrganization",
    usersMaintenance: "usersMaintenance",
    clientsMaintenance: "clientsMaintenance",
  },
  usersGuide: {
    rootPath: "usersGuide",
    first: "first",
    second: "second",
  },
  iso: {
    rootPath: "iso",
    isoDocumentations: "isoDocumentations",
    isoMaintenance: "isoMaintenance",
  },
  demand: {
    rootPath: "demand",
    newDemand: "newDemand",
    modifyDemand: "modifyDemand",
    demandHomePage: "demandHomePage",
    demandsByState: "demandsByState",
    notice: "detailedDemand",
    attachedDocuments: "attachedDocuments",
    documentations: "documentations",
    modifiedDemands: "modifiedDemands",
  },
  worktime: {
    rootPath: "worktime",
    timesheet: "timesheet",
    registeredWorks: "registeredWorks",
    quickNote: "quickNote",
    plannedHolidays: "plannedHolidays",
  },
  statistics: {
    rootPath: "statistics",
    workTime: "workTime",
    workDayMissingData: "workDayMissingData",
    byDemand: "byDemand",
    byUsers: "byUsers",
    byClients: "byClients",
    workDayByType: "workDayByType",
    reserved: "reserved",
  },
  offices: {
    rootPath: "offices",
    registry: "registry",
    inInvoices: "inInvoices",
    outInvoices: "outInvoices",
    vacations: "vacations",
    holidays: "holidays",
  },

  sysadmin: {
    rootPath : "sysadmin",
    dictionary: "dictionary",
    roleGroup: "roleGroup",
    users: "users",
    userRights: "userRights",
    partners: "partners",
    partnersUsers: "partnersUsers",
    changeTracking: "changeTracking",
  },

  group1: "group1",
  wipDemandListResponsible: "wipDemandListResponsible",
  wiDemandListArranger: "wipDemandListArranger"

};
