import { isNullOrUndefined } from 'util';
import { dictionaryQuery } from '@commons/functions/dictionary.query';
import { DictionaryEntry } from '@commons/functions/dictionary.query';
import { dateFormat } from '@entities/date.format';
import { QuickNoteDialogComponent } from '@commons/components/quick-note-dialog/quick-note-dialog.component';
import { MatDialog } from '@angular/material';
import { QuickNotePostRequest, QuickNoteResponse } from '@entities/api/bookmark.interface';
import { httpCodes, HttpService } from '@ha8rt/http.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '@config';
import { DialogButtonClass, DialogDataBinder } from '@commons/functions/dialog.data.binder';
import { DialogComponent } from '@commons/components/dialog/dialog.component';
import { SnackbarService } from '@commons/services/snackbar/snackbar.service';
import { UploadFileService } from '@commons/services/upload-file/upload-file.service';
import { UserDetailsService } from '@commons/services/user-details/user-details.service';
import { CountupTimerService } from 'src/app/ngx-timer/countup-timer/countup-timer.service';

@Injectable({
   providedIn: 'root',
})
export class TimerService {
   static quickNoteIsRunning = 'S';
   static quickNoteLabel = '';
   service: HttpService;
   oneQuickNoteService: HttpService;
   quickNoteText: string;
   noticeTypes: DictionaryEntry[] = [];
   dictionaryQueryService: HttpService;
   nowSec: number;
   midnightSec: number;
   selectedNoticeFile: FileList;
   workType: string;
   publicNotice: boolean;
   answerer: string;
   noticeType: string;

   constructor(
      private countupService: CountupTimerService,
      private http: HttpClient,
      public dialog: MatDialog,
      private snackbar: SnackbarService,
      private uploadService: UploadFileService,
   ) {
      this.service = new HttpService(this.http).setApi(routes.quickNote);
      this.oneQuickNoteService = new HttpService(this.http).setApi(routes.oneQuickNote);
      this.dictionaryQueryService = new HttpService(this.http).setApi(routes.dictionaryQuery);
   }

   get quickNoteIsRunning() {
      return TimerService.quickNoteIsRunning;
   }
   get quickNoteLabel() {
      return TimerService.quickNoteLabel;
   }

   public startTimer(pIdemandLabel: string, piUsername: string) {
      dictionaryQuery('notices-notice_type', this.noticeTypes, this.dictionaryQueryService);
      TimerService.quickNoteLabel = pIdemandLabel;
      TimerService.quickNoteIsRunning = 'R';
      // tslint:disable-next-line:prefer-const
      let now = new Date();
      let startHelper: string;
      let pausedHelper: string;
      const time: string =
         ('0' + now.getHours().toString()).substr(-2) +
         ':' +
         ('0' + now.getMinutes().toString()).substr(-2) +
         ':' +
         ('0' + now.getSeconds().toString()).substr(-2);
      const postRequest = {
         demandLabel: pIdemandLabel,
         username: piUsername,
         startTime: time,
         startDate: dateFormat(now),
      };
      this.service.post<QuickNoteResponse>(postRequest, (res) => {
         if (res.status === httpCodes.created) {
            startHelper = res.body[0].startTime
               ? ('0' + res.body[0].startTime).substr(-8)
               : now.toISOString().substr(11, 8);
            pausedHelper = res.body[0].pausedTime ? ('0' + res.body[0].pausedTime).substr(-8) : '00:00:00';
            now.setHours(
               now.getHours() - (now.getHours() - Number(startHelper.substr(0, 2)) - Number(pausedHelper.substr(0, 2))),
            );
            now.setMinutes(
               now.getMinutes() -
                  (now.getMinutes() - Number(startHelper.substr(3, 2)) - Number(pausedHelper.substr(3, 2))),
            );
            now.setSeconds(
               now.getSeconds() -
                  (now.getSeconds() - Number(startHelper.substr(6, 2)) - Number(pausedHelper.substr(6, 2))),
            );
         }
         this.countupService.startTimer(now);
      });
      this.setTimes();
      setTimeout(() => {
         this.midnightStop(piUsername, UserDetailsService.defaultWorktype);
      }, (this.midnightSec - this.nowSec) * 1000);
   }

   public pauseTimer(pIusername: string) {
      TimerService.quickNoteIsRunning = 'P';
      const now = new Date();
      const time: string =
         ('0' + now.getHours().toString()).substr(-2) +
         ':' +
         ('0' + now.getMinutes().toString()).substr(-2) +
         ':' +
         ('0' + now.getSeconds().toString()).substr(-2);
      this.countupService.pauseTimer();
      this.service.post({ demandLabel: TimerService.quickNoteLabel, username: pIusername, startTime: time }, (res) => {
         if (res.status === httpCodes.created) {
         }
      });
   }

   public stopTimer(username: string, defaultWorktype: string) {
      const paused = TimerService.quickNoteIsRunning;
      if (paused === 'R') {
         this.pauseTimer(username);
      }
      const now = new Date();
      const dialogRef = this.dialog.open(QuickNoteDialogComponent, {
         width: '70%',
         data: {
            header: 'Quick note',
            demandLabel: TimerService.quickNoteLabel,
            content: 'Comment',
            noticeText: this.quickNoteText,
            selectedNoticeFile: this.selectedNoticeFile,
            workType: this.workType,
            publicNotice: this.publicNotice,
            answerer: this.answerer,
            noticeType: this.noticeType,
         },
      });
      dialogRef.afterClosed().subscribe((result) => {
         if (result && result.submitted === 1) {
            const endOfQuickNote: string =
               ('0' + now.getHours().toString()).substr(-2) +
               ':' +
               ('0' + now.getMinutes().toString()).substr(-2) +
               ':' +
               ('0' + now.getSeconds().toString()).substr(-2);
            this.quickNoteText = result.noticeText;
            this.selectedNoticeFile = result.selectedNoticeFile;
            this.workType = result.workType;
            this.publicNotice = result.publicNotice;
            this.answerer = result.answerer;
            this.noticeType = result.noticeType;
            dictionaryQuery('notices-notice_type', this.noticeTypes, this.dictionaryQueryService);
            const postRequest: QuickNotePostRequest = {
               demandLabel: this.quickNoteLabel,
               description: this.quickNoteText,
               workDate: dateFormat(now),
               quickNoteEnd: endOfQuickNote,
               createdBy: username,
               workType: this.workType,
               publicNotice: this.publicNotice,
               answerer: this.answerer,
               noticeType: this.noticeType,
            };
            this.quickNoteSubmit(postRequest);
         } else if (result && result.submitted === 2) {
            const headerText = 'Are you sure you want to delete the current quick note?';
            const noButton = new DialogButtonClass('No');
            const yesButton = new DialogButtonClass('Yes', 1);
            const delDialogRef = this.dialog.open(
               DialogComponent,
               new DialogDataBinder(headerText, undefined, undefined, undefined, noButton, yesButton),
            );
            delDialogRef.afterClosed().subscribe((rs) => {
               if (rs === 1) {
                  const req = {
                     username,
                     demandLabel: this.quickNoteLabel,
                  };
                  this.oneQuickNoteService.patch('delete', req, async (res) => {
                     if (res.status === httpCodes.ok) {
                        this.snackbar.openAlertMsg('Successfully deleted!', 'red', 5000);
                        this.countupService.stopTimer();
                        this.setDefaultData();
                     }
                  });
               } else if (rs === undefined) {
                  if (paused === 'R') {
                     this.startTimer(TimerService.quickNoteLabel, username);
                  }
               }
            });
         } else if (result === undefined) {
            if (paused === 'R') {
               this.startTimer(TimerService.quickNoteLabel, username);
            }
         }
      });
   }

   quickNoteSubmit(postRequest: QuickNotePostRequest) {
      this.oneQuickNoteService.post<{ noticeNumber: number }>(postRequest, async (res) => {
         if (res.status === httpCodes.created) {
            if (!isNullOrUndefined(this.selectedNoticeFile) && this.selectedNoticeFile.length > 0) {
               let fileNumber = 0;
               const noticeNumber = res.body.noticeNumber;
               for (let i = 0; i < this.selectedNoticeFile.length; i++) {
                  (
                     await this.uploadService.pushFileToStorage(
                        this.selectedNoticeFile.item(i),
                        TimerService.quickNoteLabel,
                        noticeNumber,
                     )
                  ).subscribe((event) => {
                     if (event instanceof HttpResponse) {
                        fileNumber += 1;
                        if (fileNumber === this.selectedNoticeFile.length) {
                           this.snackbar.openAlertMsg('Quick note is successfully submitted!', 'lightgreen', 5000);
                           this.countupService.stopTimer();
                           this.setDefaultData();
                        }
                     }
                  });
               }
            } else {
               this.snackbar.openAlertMsg('Quick note is successfully submitted!', 'lightgreen', 5000);
               this.countupService.stopTimer();
               this.setDefaultData();
            }
         }
      });
   }

   public midnightStop(username: string, defaultWorktype: string) {
      if (TimerService.quickNoteIsRunning === 'R') {
         this.pauseTimer(username);
      }
      const now = new Date();
      if (TimerService.quickNoteIsRunning !== 'S') {
         const dialogRef = this.dialog.open(QuickNoteDialogComponent, {
            width: '70%',
            data: {
               header: 'Quick note',
               demandLabel: TimerService.quickNoteLabel,
               content: 'It is required to finish the quick note',
               noticeText: this.quickNoteText,
               onlyDelete: true,
               selectedNoticeFile: this.selectedNoticeFile,
               workType: this.workType,
               publicNotice: this.publicNotice,
               answerer: this.answerer,
               noticeType: this.noticeType,
            },
         });
         dialogRef.afterClosed().subscribe((result) => {
            if (result && result.submitted === 1) {
               const endOfQuickNote: string =
                  ('0' + now.getHours().toString()).substr(-2) +
                  ':' +
                  ('0' + now.getMinutes().toString()).substr(-2) +
                  ':' +
                  ('0' + now.getSeconds().toString()).substr(-2);
               this.quickNoteText = result.noticeText;
               this.selectedNoticeFile = result.selectedNoticeFile;
               this.workType = result.workType;
               this.publicNotice = result.publicNotice;
               this.answerer = result.answerer;
               this.noticeType = result.noticeType;
               dictionaryQuery('notices-notice_type', this.noticeTypes, this.dictionaryQueryService);
               const postRequest: QuickNotePostRequest = {
                  demandLabel: this.quickNoteLabel,
                  description: this.quickNoteText,
                  workDate: dateFormat(now),
                  quickNoteEnd: endOfQuickNote,
                  createdBy: username,
                  workType: this.workType,
                  publicNotice: this.publicNotice,
                  answerer: this.answerer,
                  noticeType: this.noticeType,
               };
               this.quickNoteSubmit(postRequest);
            } else if ((result && result.submitted === 2) || result === undefined) {
               const headerText = 'Are you sure you want to exit from quick note?';
               const contentText = 'The quick note will be deleted!';
               const noButton = new DialogButtonClass('No');
               const yesButton = new DialogButtonClass('Yes', 1);
               const delDialogRef = this.dialog.open(
                  DialogComponent,
                  new DialogDataBinder(headerText, undefined, contentText, undefined, noButton, yesButton),
               );
               delDialogRef.afterClosed().subscribe((rs) => {
                  if (rs === 1) {
                     const req = {
                        username,
                        demandLabel: this.quickNoteLabel,
                     };
                     this.oneQuickNoteService.patch('delete', req, async (res) => {
                        if (res.status === httpCodes.ok) {
                           this.snackbar.openAlertMsg('Successfully deleted!', 'red', 5000);
                           this.countupService.stopTimer();
                           this.setDefaultData();
                        }
                     });
                  }
                  dialogRef.close();
               });
            }
         });
      }
   }

   public async timerSet(piUsername: string) {
      this.oneQuickNoteService.get<QuickNoteResponse>({ username: piUsername }, (res) => {
         if (res.status === httpCodes.created) {
            if (res.body.length) {
               const now = new Date();
               if (res.body[0].startDate !== dateFormat(new Date())) {
                  const dialogRef = this.dialog.open(QuickNoteDialogComponent, {
                     width: '70%',
                     data: {
                        header: 'Quick note',
                        demandLabel: TimerService.quickNoteLabel,
                        content: "It is required to finish the previous day's quick note",
                        noticeText: this.quickNoteText,
                        onlyDelete: true,
                        selectedNoticeFile: this.selectedNoticeFile,
                        workType: this.workType,
                        publicNotice: this.publicNotice,
                        answerer: this.answerer,
                        noticeType: this.noticeType,
                     },
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                     if (result && result.submitted === 1) {
                        const endOfQuickNote: string =
                           ('0' + now.getHours().toString()).substr(-2) +
                           ':' +
                           ('0' + now.getMinutes().toString()).substr(-2) +
                           ':' +
                           ('0' + now.getSeconds().toString()).substr(-2);
                        this.quickNoteText = result.noticeText;
                        this.selectedNoticeFile = result.selectedNoticeFile;
                        this.workType = result.workType;
                        this.publicNotice = result.publicNotice;
                        this.answerer = result.answerer;
                        this.noticeType = result.noticeType;
                        dictionaryQuery('notices-notice_type', this.noticeTypes, this.dictionaryQueryService);
                        const postRequest: QuickNotePostRequest = {
                           demandLabel: res.body[0].demandLabel,
                           description: this.quickNoteText,
                           workDate: res.body[0].startDate,
                           quickNoteEnd: endOfQuickNote,
                           createdBy: piUsername,
                           prevDay: true,
                           workType: this.workType,
                           publicNotice: this.publicNotice,
                           answerer: this.answerer,
                           noticeType: this.noticeType,
                        };
                        this.quickNoteSubmit(postRequest);
                     } else if ((result && result.submitted === 2) || result === undefined) {
                        const headerText = 'Are you sure you want to exit from quick note?';
                        const contentText = 'The quick note will be deleted!';
                        const noButton = new DialogButtonClass('No');
                        const yesButton = new DialogButtonClass('Yes', 1);
                        const delDialogRef = this.dialog.open(
                           DialogComponent,
                           new DialogDataBinder(headerText, undefined, contentText, undefined, noButton, yesButton),
                        );
                        delDialogRef.afterClosed().subscribe((rs) => {
                           if (rs === 1) {
                              const req = {
                                 piUsername,
                                 demandLabel: this.quickNoteLabel,
                              };
                              this.oneQuickNoteService.patch('delete', req, async (resp) => {
                                 if (resp.status === httpCodes.ok) {
                                    this.snackbar.openAlertMsg('Successfully deleted!', 'red', 5000);
                                    this.countupService.stopTimer();
                                    this.setDefaultData();
                                 }
                              });
                           }
                           dialogRef.close();
                        });
                     }
                  });
               } else {
                  TimerService.quickNoteLabel = res.body[0].demandLabel;
                  TimerService.quickNoteIsRunning = res.body[0].pauseTime !== null ? 'P' : 'R';
                  const startHelper = ('0' + res.body[0].startTime).substr(-8);
                  const pausedHelper = res.body[0].pausedTime ? ('0' + res.body[0].pausedTime).substr(-8) : '00:00:00';
                  const pauseHelper = res.body[0].pauseTime ? ('0' + res.body[0].pauseTime).substr(-8) : '00:00:00';
                  if (TimerService.quickNoteIsRunning === 'R') {
                     now.setHours(
                        now.getHours() -
                           (now.getHours() - Number(startHelper.substr(0, 2)) - Number(pausedHelper.substr(0, 2))),
                     );
                     now.setMinutes(
                        now.getMinutes() -
                           (now.getMinutes() - Number(startHelper.substr(3, 2)) - Number(pausedHelper.substr(3, 2))),
                     );
                     now.setSeconds(
                        now.getSeconds() -
                           (now.getSeconds() - Number(startHelper.substr(6, 2)) - Number(pausedHelper.substr(6, 2))),
                     );
                     this.countupService.startTimer(now);
                     this.setTimes();
                     // tslint:disable-next-line:max-line-length
                     setTimeout(() => {
                        this.midnightStop(piUsername, UserDetailsService.defaultWorktype);
                     }, (this.midnightSec - this.nowSec) * 1000);
                  } else if (TimerService.quickNoteIsRunning === 'P') {
                     now.setHours(
                        now.getHours() -
                           (Number(pauseHelper.substr(0, 2)) -
                              Number(startHelper.substr(0, 2)) -
                              Number(pausedHelper.substr(0, 2))),
                     );
                     now.setMinutes(
                        now.getMinutes() -
                           (Number(pauseHelper.substr(3, 2)) -
                              Number(startHelper.substr(3, 2)) -
                              Number(pausedHelper.substr(3, 2))),
                     );
                     now.setSeconds(
                        now.getSeconds() -
                           (Number(pauseHelper.substr(6, 2)) -
                              Number(startHelper.substr(6, 2)) -
                              Number(pausedHelper.substr(6, 2))),
                     );
                     this.countupService.startTimer(now);
                     this.countupService.pauseTimer();
                  }
               }
            } else {
               TimerService.quickNoteLabel = '';
               TimerService.quickNoteIsRunning = 'S';
            }
         }
      });
   }

   async setTimes() {
      const nowTime = new Date();
      this.nowSec = nowTime.getHours() * 3600 + nowTime.getMinutes() * 60 + nowTime.getSeconds();
      const midnight = new Date(new Date().setHours(23, 59, 55));
      this.midnightSec = midnight.getHours() * 3600 + midnight.getMinutes() * 60 + midnight.getSeconds();
   }

   setDefaultData() {
      TimerService.quickNoteIsRunning = 'S';
      TimerService.quickNoteLabel = '';
      this.quickNoteText = '';
      this.selectedNoticeFile = undefined;
      this.workType = '';
      this.publicNotice = null;
      this.answerer = '';
      this.noticeType = null;
   }
}
