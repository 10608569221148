import { UserDetailsService, SnackbarService, SetTitleService } from "@commons/services";
import { MatTableDataSource } from "@angular/material";
import { ChangedPartners, UsersPartnersGetResponse } from "@entities/api/users-partners.interface";
import { UserDetails, UserParams } from "@entities/api/users.interfaces";
import { httpCodes, HttpService } from "@ha8rt/http.service";
import { HttpClient } from "@angular/common/http";
import { DictionaryEntry, dictionaryQuery } from "@commons/functions/dictionary.query";
import { NgForm } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { routes } from "@config";

const workTypeGroupName = "notices-work_type";
type TableRowData = UsersPartnersGetResponse[number];

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  dataSource: MatTableDataSource<TableRowData>;
  displayedRecords: TableRowData[] = [];
  emailListForEmailService: HttpService;
  emailList: string[] = [];
  dictionaryQueryService: HttpService;
  service: HttpService;
  settingsService: HttpService;
  usersPartnersService: HttpService;
  usersEmailCopyService: HttpService;
  userDetailsPanel = false;
  userDetailsExpand = false;
  hideNewPassword = true;
  hideNewPasswordAgain = true;
  workTypes: DictionaryEntry[] = [];
  userDetails: Partial<UserDetails & UserParams> & {newPassword?: string; newPasswordAgain?: string; } = {};
  changedPartners: ChangedPartners[] = [];
  displayedColumns = ["nameOnHomePage", "checked"];
  isWorker = UserDetailsService.isWorker;
  selectedEmails: string[] = [];
  get mobile() { return UserDetailsService.mobile; }

  constructor(private http: HttpClient, private snackbar: SnackbarService, private title: SetTitleService) {
    this.dictionaryQueryService = new HttpService(this.http).setApi(routes.dictionaryQuery);
    this.service = new HttpService(this.http).setApi(routes.userDetails);
    this.settingsService = new HttpService(this.http).setApi(routes.settings);
    this.usersPartnersService = new HttpService(this.http).setApi(routes.usersPartners);
    this.usersEmailCopyService = new HttpService(this.http).setApi(routes.usersEmailCopy);
    this.emailListForEmailService = new HttpService(this.http).setApi(routes.emailList);
    this.emailListForEmailService.get<string[]>(undefined, (res) => {
      this.emailList = res.body;
    });
  }

  ngOnInit() {
    this.title.setTitle("Settings");
    dictionaryQuery(workTypeGroupName, this.workTypes, this.dictionaryQueryService);
    this.selectData();
  }

  settingsSubmit(form: NgForm) {
    const emails: string[] = [];
    this.selectedEmails.forEach(item => {
      item.includes("(") === true ? emails.push(item.slice(item.indexOf("(") + 1, -1)) : emails.push(item);
    });
    const requestData = {
      username: UserDetailsService.username,
      firstname: form.value.firstname,
      lastname: form.value.lastname,
      email: form.value.email,
      newPassword: form.value.newPassword.length > 0 ? form.value.newPassword : undefined,
      allUsersWt: form.value.allUsersWt || false,
      canSeeAutNotices: form.value.canSeeAutNotices || false,
      firstNoticeOnTop: form.value.firstNoticeOnTop || false,
      defaultWorktype: form.value.defaultWorktype,
      emails
    };
    this.settingsService.patch<unknown>("update", requestData, (res) => {
      if (res.status === httpCodes.created) {
        if (UserDetailsService.firstname !== form.value.firstname) {
          UserDetailsService.firstname = form.value.firstname;
        }
        if (UserDetailsService.lastname !== form.value.lastname) {
          UserDetailsService.lastname = form.value.lastname;
        }
        if (UserDetailsService.allUsersWt !== form.value.allUsersWt) {
          UserDetailsService.allUsersWt = form.value.allUsersWt;
        }
        if (UserDetailsService.defaultWorktype !== form.value.defaultWorktype) {
          UserDetailsService.defaultWorktype = form.value.defaultWorktype;
        }
        if (UserDetailsService.canSeeAutNotices !== form.value.canSeeAutNotices) {
          UserDetailsService.canSeeAutNotices = form.value.canSeeAutNotices;
        }
        if (UserDetailsService.firstNoticeOnTop !== form.value.firstNoticeOnTop) {
          UserDetailsService.firstNoticeOnTop = form.value.firstNoticeOnTop;
        }
        // Visszajelzés a felhasználónak
        this.snackbar.openAlertMsg(
          "Successfully modified!",
          "yellow",
          5000
        );
        this.userDetailsPanel = false;
      }
    });
  }

  modifyPartners() {
    Promise.all(this.dataSource.data.map((data) => {
      if (data.checked === true) {
        this.changedPartners.push({nameOnHomePage: data.nameOnHomePage});
      }
    }));
    this.usersPartnersService.patch<unknown>("update", {partners: this.changedPartners, username: UserDetailsService.username}, (res) => {
      if (res.status === httpCodes.created) {
        // Újra felolvassuk az adatokat
        this.selectData();
        // Visszajelzés a felhasználónak
        this.snackbar.openAlertMsg(
          "Successfully modified!",
          "yellow",
          5000
        );
        // A beviteli részt visszaállítjuk üresre
        this.changedPartners = [];
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
  });
  }

  selectAllOrNone(all: boolean) {
    Promise.all(this.dataSource.data.map((data) => {
        data.checked = all;
    }));
  }

  selectData() {
    this.service.get<UserDetails & UserParams>(
      { username: UserDetailsService.username },
      (res) => {
        if (res.status === httpCodes.created && res.body) {
          this.userDetails = res.body;
          this.userDetails.defaultWorktype = this.userDetails.defaultWorktype ? this.userDetails.defaultWorktype : "";
        }
      }
    );
    this.dataSource = new MatTableDataSource(this.displayedRecords);
    this.usersPartnersService.get<UsersPartnersGetResponse>({username: UserDetailsService.username}, (res) => {
      if (res.status === httpCodes.created && res.body) {
        this.displayedRecords = res.body;
        this.dataSource.data = this.displayedRecords;
      }
    });
    this.usersEmailCopyService.get<string[]>(undefined, (res) => {
      if (res.status === httpCodes.created && res.body) {
        this.selectedEmails = res.body;
      }
    });

    this.emailListForEmailService.get<string[]>(undefined, (res) => {
      this.emailList = res.body;
    });
  }

}
