import { dateFormat } from "@entities/date.format";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DictionaryEntry, dictionaryQuery } from "@commons/functions";
import { SearchRequest, SearchResponse, SearchResultType } from "@entities/api/search.interface";
import { httpCodes, HttpService } from "@ha8rt/http.service";
import { routes } from "@config";
import { HttpClient } from "@angular/common/http";
import { UserDetailsService, SetTitleService } from "@commons/services";
import { NgForm } from "@angular/forms";
import { PartnerlistGetResponse } from "@entities/api/partnerlist.interface";
import { MatSort, MatTableDataSource } from "@angular/material";

type TableRowData = SearchResponse[number];
const stateGroupName = "demand-state";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  element: Partial<SearchRequest> = {
    demandState: 0,
    partner: "",
    sensitivity: false,
  };
  states: DictionaryEntry[] = [];
  dictionaryQueryService: HttpService;
  searchService: HttpService;
  filledDPSD = false;
  filledDPED = false;
  filledLMSD = false;
  filledLMED = false;
  partnerListService: HttpService;
  partnerList: PartnerlistGetResponse;
  dataSource: MatTableDataSource<SearchResultType>;
  displayedRecords: TableRowData[] = [];
  displayedFirst = ["demandLabel", "partner", "demandName"];
  displayedSecond = ["demandDescription"];
  displayedThird = ["notices", "noticeCreationDate", "noticeDescription"];
  displayedNotices = ["noticeDetails", "noticeDescription"];
  listedResults: SearchResultType[] = [];
  get mobile() { return UserDetailsService.mobile; }
  get colleague() { return UserDetailsService.isWorker; }
  allWords = [
    {name: "Full phrase"},
    {name: "To all words"}
  ];
  chosenMode = "";
  andOr = [
    {name: "and"},
    {name: "or"}
  ];
  chosenAndOr = "";
  disableSubmit = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private http: HttpClient, private title: SetTitleService) {
    this.dictionaryQueryService = new HttpService(this.http).setApi(routes.dictionaryQuery);
    this.searchService = new HttpService(this.http).setApi(routes.search);
    this.partnerListService = new HttpService(this.http).setApi(routes.ownpartnerlist);
    this.partnerListService.get<PartnerlistGetResponse>(undefined, (res) => {
      this.partnerList = res.body;
      if (!this.colleague) {
        this.element.partner = this.partnerList[0].name;
      }
    });
  }

  ngOnInit() {
    this.title.setTitle("Search");
    dictionaryQuery(stateGroupName, this.states, this.dictionaryQueryService);
    this.chosenMode = this.allWords[0].name;
    this.chosenAndOr = this.andOr[0].name;
  }

  searchSubmit(form: NgForm) {
    this.disableSubmit = true;
    document.body.style.cursor = "wait";
    const requestData: SearchRequest = {
      text: form.value.text,
      sensitivity: form.value.sensitivity,
      allWords: this.chosenMode === "Full phrase" ? true : false,
      andOr: this.chosenAndOr,
      dpStartDate: dateFormat(form.value.dpStartDate || "0001-01-01"),
      dpEndDate: dateFormat(form.value.dpEndDate || new Date()),
      lmStartDate: dateFormat(form.value.lmStartDate || "0001-01-01"),
      lmEndDate: dateFormat(form.value.lmEndDate || new Date()),
      partner: this.colleague ? form.value.partner : this.element.partner,
      demandState: form.value.demandState,
      colleague: this.colleague,
    };
    this.listedResults = [];
    this.dataSource = new MatTableDataSource(this.listedResults);
    this.searchService.get<SearchResponse>(requestData, (res) => {
      if (res.status === httpCodes.created && res.body.length) {
        this.displayedRecords = res.body;
        this.displayedRecords = this.displayedRecords.sort((a, b) =>
          a.demandCreationDate < b.demandCreationDate ? -1 : a.demandCreationDate > b.demandCreationDate ? 1 : 0);
        this.displayedRecords.forEach(item => {
          const notice = {
            noticeNumber: item.noticeNumber || 0,
            noticeDescription: item.noticeDescription || null,
            publicNotice: item.publicNotice || null,
            createdBy: item.createdBy || null,
            noticeCreationDate: item.noticeCreationDate || null
          };
          if (this.listedResults.length === 0) {
            this.listedResults.push({
              partnerName: item.partnerName,
              demandState: item.demandState,
              demandLabel: item.demandLabel,
              publicDemand: item.publicDemand,
              demandCreationDate: item.demandCreationDate,
              demandName: item.demandName,
              demandDescription: item.demandDescription,
              notices: [notice]
            });
          } else {
            const objIndex = this.listedResults.findIndex((obj => obj.demandLabel === item.demandLabel));
            if (objIndex === -1) {
              this.listedResults.push({
                partnerName: item.partnerName,
                demandState: item.demandState,
                demandLabel: item.demandLabel,
                publicDemand: item.publicDemand,
                demandCreationDate: item.demandCreationDate,
                demandName: item.demandName,
                demandDescription: item.demandDescription,
                notices: [notice]
              });
            } else {
              this.listedResults[objIndex].notices.push(notice);
            }
          }
        });
        this.dataSource.data = this.listedResults;
      }
      this.disableSubmit = false;
      document.body.style.cursor = "default";
    });
  }

  dateChange(form: NgForm) {
  // if (form.value.dpStartDate) {
    this.filledDPSD = form.value.dpStartDate ? true : false;
  // }
  // if (form.value.dpEndDate) {
    this.filledDPED = form.value.dpEndDate ? true : false;
  // }
  // if (form.value.lmStartDate) {
    this.filledLMSD = form.value.lmStartDate ? true : false;
  // }
  // if (form.value.lmEndDate) {
    this.filledLMED = form.value.lmEndDate ? true : false;
  // }
    return;
  }

  fing() {
    this.element.allWords = !(this.element.allWords);
  }
}
