import { Injectable } from "@angular/core";
import { UserPermissions } from "@entities/api/users.interfaces";

@Injectable({
  providedIn: "root"
})
export class UserDetailsService {
  static forgottenPassword = false;
  static loggedIn = false;
  static firstname: string;
  static lastname: string;
  static username: string;
  static location: string;
  static allUsersWt: boolean;
  static defaultWorktype: string;
  static canSeeAutNotices: boolean;
  static firstNoticeOnTop: boolean;
  static permissionsForUser: UserPermissions;
  /** Teszteléshez */
  static database: string;
  static isWorker: boolean;
  static mobile: boolean;
  static canTranslate: boolean;
  static userLang: string;

  constructor() {}

  get forgottenPassword() { return UserDetailsService.forgottenPassword; }
  get loggedIn() { return UserDetailsService.loggedIn; }
  get firstname() { return UserDetailsService.firstname; }
  get lastname() { return UserDetailsService.lastname; }
  get username() { return UserDetailsService.username; }
  get location() { return UserDetailsService.location; }
  get database() { return UserDetailsService.database; }
  get allUsersWt() { return UserDetailsService.allUsersWt; }
  get defaultWorktype() { return UserDetailsService.defaultWorktype; }
  get canSeeAutNotices() { return UserDetailsService.canSeeAutNotices; }
  get firstNoticeOnTop() { return UserDetailsService.firstNoticeOnTop; }
  get permissionsForUser() { return UserDetailsService.permissionsForUser; }
  get isWorker() { return UserDetailsService.isWorker; }
  get mobile() { return UserDetailsService.mobile; }
  get canTranslate() { return UserDetailsService.canTranslate; }
  get userLang() { return UserDetailsService.userLang; }
}
