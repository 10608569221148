import { AfterContentInit, Directive, DoCheck, HostBinding, Input, OnChanges } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, Router, Routes } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { hasPermission } from '../../functions/has-permission';
import { asyncSome } from '../../functions/utils';
import { EventService } from '@commons/services/event.service';
import { Route } from '@angular/compiler/src/core';

const debugSecuredDirective = false;

// tslint:disable-next-line:no-conflicting-lifecycle
@Directive({
   // tslint:disable-next-line:directive-selector
   selector: '[secured]',
})
export class SecuredDirective implements AfterContentInit {
   @HostBinding('hidden') hideRouterLink: boolean;
   // tslint:disable-next-line:no-input-rename
   @Input('routerLink') routeParams: string;

   route: Route;

   constructor(private router: Router, eventService: EventService) {
      // lazy load miatt kell

      router.events.subscribe(async (event) => {
         if (event instanceof RouteConfigLoadEnd) {
            if (this.routeParams.toString().includes(event.route.path)) {
               eventService.ModuleLoadedEvent.subscribe((routesLoadedEvent: Routes) => {
                  if (routesLoadedEvent) {
                     if (this.route && (!this.route.children || this.route.children.length === 0)) {
                        this.router.config[this.router.config.indexOf(this.route)].children = routesLoadedEvent;
                        this.log(this.router.config);
                     }
                     this.checker(this.router.config);
                  }
               });
            }
         }
      });
   }

   async delay(ms: number) {
      return new Promise((resolve) => {
         setTimeout(resolve, ms);
      });
   }

   // TODO module betöltés után újrafuttatás v path + jogosultság átrendezése rendezése
   async ngAfterContentInit() {
      await this.checker(this.router.config);
   }

   private async checker(routes: Routes) {
      this.log('checker called');
      let show = false;
      const stringRoute = this.routeParams.toString();
      this.log('routeParam: ' + stringRoute + ' typeof ' + typeof stringRoute);
      let splitted: string[];
      if (stringRoute.includes(',')) {
         splitted = stringRoute.split(',');
      } else if (stringRoute.includes('/')) {
         splitted = stringRoute.split('/');
      } else {
         splitted = [stringRoute];
      }

      show = await this.checkRoute(routes, splitted);

      this.log(`Route (${stringRoute}) link ${show ? 'visible' : 'hidden'}`);
      this.hideRouterLink = !show;
   }

   private async checkRoute(routes: Routes, pathParts: string[], depth: number = 1): Promise<boolean> {
      this.log(`${'\t'.repeat(depth)} check function called. passed paths: ${pathParts}`);
      for (const route of routes) {
         // tslint:disable-next-line:max-line-length
         this.log(
            `${'\t'.repeat(depth)} Checking ${route.path} against ${pathParts[0]} which is ${
               (!isNullOrUndefined(pathParts[0]) && route.path === pathParts[0].toString()) ||
               isNullOrUndefined(pathParts[0])
            }`,
         );

         if (!isNullOrUndefined(pathParts[0]) && route.path === pathParts[0].toString()) {
            this.log(`${'\t'.repeat(depth)} subpath ok.`);
            if (!this.route) {
               this.route = route;
            }
            if (route.children && route.children.length > 0) {
               this.log(`${'\t'.repeat(depth)} route has chidren... checking subpaths..`);
               pathParts.shift();
               return await this.checkRoute(route.children, pathParts, depth + 1);
            }

            if (route.data && route.data.secured) {
               const perms = route.data.secured as string[];

               this.log(`${'\t'.repeat(depth)} route's data ok and secured with perms: ${perms}`);
               const res = await asyncSome(perms, hasPermission);
               if (pathParts.length === 1) {
                  return res;
               }
            }

            // Ha nincs megadva a jogosultság, amit ellenőrzni kell, akkor megmutatjuk az elemet.
            this.log(`${'\t'.repeat(depth)} route skipped, hidden.`);
         } else if (isNullOrUndefined(pathParts[0]) && routes.length > 0) {
            if (route.data && route.data.secured) {
               const perms = route.data.secured as string[];

               this.log(`${'\t'.repeat(depth)} route's data ok and secured with perms: ${perms}`);
               if (await asyncSome(perms, hasPermission)) {
                  return true;
               }
            }
         }
      }
      return false;
   }

   private log(msg: any): void {
      // tslint:disable-next-line:no-console
      if (debugSecuredDirective) {
         console.log(msg);
      }
   }
}
