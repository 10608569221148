import { UserDetailsService } from "@commons/services";
import { Router, ActivatedRoute } from "@angular/router";
import { routes, paths } from "@config";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { HttpService, isResultValid, httpCodes, Redirect } from "@ha8rt/http.service";
import { DialogDataBinder, DialogButtonClass } from "../../functions/dialog.data.binder";
import { DialogComponent } from "../dialog/dialog.component";
import { Subscription } from "rxjs";
import { CountdownComponent, CountdownConfig } from "ngx-countdown";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { LoaderService } from "@commons/services/loader/loader.service";
import { MatDialog, MatDialogConfig } from "@angular/material";

@Component({
  selector: "app-my-countdown",
  templateUrl: "./my-countdown.component.html",
  styleUrls: ["./my-countdown.component.scss"],
})
export class MyCountdownComponent implements OnInit, OnDestroy {
  redirect: Redirect;
  service: HttpService;
  sessionService: HttpService;
  sessServ: HttpService;
  sessionDialog = false;
  sub: Subscription;
  config: CountdownConfig;
  data: MatDialogConfig;
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;
  constructor(
    private Loader: LoaderService,
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.sessionService = new HttpService(this.http).setApi(routes.session);
    this.sessServ = new HttpService(this.http).setApi(routes.sessionCount);
    this.redirect = new Redirect(this.router, this.route);
  }

  ngOnInit() {
    this.sub = this.Loader.loadNum.subscribe((value) => {
      this.countdown.stop();
      this.config = {
        leftTime: value,
        notify: [300],
      };
      this.countdown.begin();
    });
    this.config = {
      leftTime: this.Loader.currValue,
      notify: [300],
    };
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  countdownHandler(event) {
    if (event.action === "notify") {
      const noButton = new DialogButtonClass("No");
      const yesButton = new DialogButtonClass("Yes", 1);
      const dialogRef = this.dialog.open(
        DialogComponent,
        new DialogDataBinder(
          "The site will exit soon!",
          undefined,
          "Do you want to stay?",
          undefined,
          noButton,
          yesButton
        )
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 1) {
          this.sessionService.get({ renew: UserDetailsService.loggedIn }, () => {});
        }
      });
    } else if (event.action === "done") {
      this.dialog.closeAll();
      this.logOut(this.sessServ, this.router);
    }
  }

  logOut(service: HttpService, router: Router) {
    service.delete(undefined, async (value: HttpResponse<any>) => {
      if (
        isResultValid(value) ||
        value.status === httpCodes.forbidden ||
        value.status === httpCodes.unauthorized
      ) {
        UserDetailsService.loggedIn = false;
        router.navigate([paths.login]);
        this.redirect.setDefaultUrl(paths.login);
      }
    });
  }
}
