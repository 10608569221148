import { Component, OnInit } from "@angular/core";
import { UserDetailsService } from "@commons/services";

@Component({
  selector: "app-translations",
  templateUrl: "./translations.component.html",
  styleUrls: ["./translations.component.scss"]
})
export class TranslationsComponent implements OnInit {
  modules = {};
  demandTrPanel = true;
  noticeTrPanel = true;
  // demandList

  get mobile() { return UserDetailsService.mobile; }

  constructor() {
    this.modules = {
      toolbar: [
        ["bold", "italic", "underline", "clean"],
      ],
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler(range, context) {
              return true;
            },
          },
          "list autofill": {
            prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/
          }
        }
      }
    };
  }

  ngOnInit() {
  }

  demandSubmit(demandLabel: string, newDescription: string) {

  }

  noticeSubmit(demandLabel: string, newNotice: string) {

  }

}
