import { DeviceDetectorService } from 'ngx-device-detector';
import { Language } from '@entities/api/translation.interfaces';
import { UsersLanguagePatchRequest } from '@entities/api/users.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AppDateAdapter, routes, testSzegedswLogo } from '@config';
// import { AppDateAdapter, routes, szegedswLogo, testSzegedswLogo } from "@config";
import {
   Router,
   NavigationEnd,
   ActivatedRoute,
   Routes,
   RouteConfigLoadEnd,
   RouteConfigLoadStart,
} from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { HttpService, httpCodes, isResultValid, Redirect } from '@ha8rt/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { paths } from '@config';
import { DateAdapter, MatDialog } from '@angular/material';
import { languageSettingsStore } from '@commons/functions/languageSettings.store';
import { translateServiceSet } from '@commons/functions/translateService.set';
import {
   SnackbarService,
   TimerService,
   UserDetailsService,
   QuickMenuService,
   EventService,
   LoaderService,
} from '@commons/services';
import { countUpTimerConfigModel, timerTexts } from './ngx-timer/countup-timer/countup-timer.model';
import { CountupTimerService } from './ngx-timer/countup-timer/countup-timer.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
   static isFirstRefresh = true;
   static router: Router;
   static spinner: NgxSpinnerService;
   static loader: boolean;

   // logo = szegedswLogo;
   logo = testSzegedswLogo;
   sessionService: HttpService;
   usersLanguageService: HttpService;
   langService: HttpService;
   service: HttpService;
   routerSubscription: Subscription;
   windowWidthSmall = window.innerWidth < 1000;
   sidenavToggled = false;
   redirect: Redirect;
   pageYoffset = 0;
   pageXoffset = 0;
   testConfig: countUpTimerConfigModel;

   @HostListener('window:scroll', ['$event']) onScroll(event) {
      this.pageYoffset = window.pageYOffset;
      this.pageXoffset = window.pageXOffset;
   }

   get quickNoteLabel() {
      return TimerService.quickNoteLabel;
   }
   get quickNoteIsRunning() {
      return TimerService.quickNoteIsRunning;
   }
   get loggedIn() {
      return UserDetailsService.loggedIn;
   }
   get firstname() {
      return UserDetailsService.firstname;
   }
   get lastname() {
      return UserDetailsService.lastname;
   }
   get username() {
      return UserDetailsService.username;
   }
   get database() {
      return UserDetailsService.database;
   }
   get mobile() {
      return UserDetailsService.mobile;
   }
   get timesheetMenu() {
      return QuickMenuService.timesheetMenu;
   }
   get quickNoteMenu() {
      return QuickMenuService.quickNoteMenu;
   }
   get demandHomePageMenu() {
      return QuickMenuService.demandHomePageMenu;
   }
   get newDemandMenu() {
      return QuickMenuService.newDemandMenu;
   }
   get modifiedDemandsMenu() {
      return QuickMenuService.modifiedDemandsMenu;
   }
   get usersMaintenanceMenu() {
      return QuickMenuService.usersMaintenanceMenu;
   }
   get clientsMaintenanceMenu() {
      return QuickMenuService.clientsMaintenanceMenu;
   }
   get translationsMenu() {
      return QuickMenuService.translationsMenu;
   }
   get attachedDocumentsMenu() {
      return QuickMenuService.attachedDocumentsMenu;
   }
   get documentationsMenu() {
      return QuickMenuService.documentationsMenu;
   }
   get sysAdminMenu() {
      return QuickMenuService.sysAdminMenu;
   }
   get statisticsMenu() {
      return QuickMenuService.statisticsMenu;
   }
   get officesMenu() {
      return QuickMenuService.officesMenu;
   }
   get isoMenu() {
      return QuickMenuService.isoMenu;
   }
   get loader() {
      return LoaderService.loading;
   }
   get language() {
      return UserDetailsService.userLang;
   }

   constructor(
      private router: Router,
      private spinner: NgxSpinnerService,
      private translate: TranslateService,
      private http: HttpClient,
      private route: ActivatedRoute,
      public dialog: MatDialog,
      public countupService: CountupTimerService,
      private timer: TimerService,
      private snackbar: SnackbarService,
      private eventService: EventService,
      private deviceService: DeviceDetectorService,
      private dateAdapter: DateAdapter<Date>,
   ) {
      this.spinnerFnc(true);
      router.events.subscribe(async (event) => {
         if (event instanceof RouteConfigLoadEnd) {
            for (const r of this.router.config) {
               if (r.path.includes(event.route.path)) {
                  eventService.ModuleLoadedEvent.subscribe((routesLoadedEvent: Routes) => {
                     if (routesLoadedEvent) {
                        if (!r.children || r.children.length === 0) {
                           this.router.config[this.router.config.indexOf(r)].children = routesLoadedEvent;
                        }
                     }
                  });
                  break;
               }
            }
         }
      });

      this.langService = new HttpService(this.http).setApi(routes.translation);
      this.redirect = new Redirect(this.router, this.route);
      this.redirect.setDefaultUrl(paths.login);
      this.redirect.setReloadUrl(paths.reload);
      this.translate.setDefaultLang('en');
      AppComponent.router = this.router;
      AppComponent.spinner = this.spinner;
      this.sessionService = new HttpService(this.http).setApi(routes.session);
      this.usersLanguageService = new HttpService(this.http).setApi(routes.usersLanguage);
      this.routerSubscription = this.router.events.subscribe((value: any) => {
         if (value instanceof NavigationEnd) {
            this.redirect.setUrlAfterRedirects(value.urlAfterRedirects);
         }
      });
      window.onresize = () => {
         this.windowWidthSmall = window.innerWidth < 1000;
      };
      UserDetailsService.mobile = !this.deviceService.isDesktop();
      this.spinnerFnc(false);
   }

   static get urlAfterRedirects(): string {
      return localStorage.getItem('urlAfterRedirects');
   }
   static set urlAfterRedirects(urlAfterRedirects: string) {
      if (!urlAfterRedirects) {
         localStorage.removeItem('urlAfterRedirects');
      } else {
         localStorage.setItem('urlAfterRedirects', urlAfterRedirects);
      }
   }

   public spinnerFnc(loading: boolean) {
      if (loading === true) {
         if (LoaderService.loading !== true) {
            LoaderService.loading = true;
         }
      } else {
         LoaderService.loading = false;
      }
   }

   ngOnInit(): void {
      this.spinnerFnc(true);
      this.testConfig = new countUpTimerConfigModel();
      this.testConfig.timerClass = 'test_Timer_class';
      this.testConfig.timerTexts = new timerTexts();
      this.testConfig.timerTexts.hourText = ':';
      this.testConfig.timerTexts.minuteText = ':';
      this.testConfig.timerTexts.secondsText = ' ';
      this.router.events.subscribe((event) => {
         if (event instanceof RouteConfigLoadStart) {
            this.spinnerFnc(true);
         } else if (event instanceof RouteConfigLoadEnd) {
            this.spinnerFnc(false);
         }
      });
   }

   ngAfterViewInit() {
      this.spinnerFnc(false);
   }

   ngOnDestroy(): void {
      this.routerSubscription.unsubscribe();
   }

   scrollToTopEvent() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
   }

   startTimer() {
      this.timer.startTimer(this.quickNoteLabel, UserDetailsService.username);
   }

   pauseTimer() {
      this.timer.pauseTimer(UserDetailsService.username);
   }

   stopTimer() {
      this.timer.stopTimer(UserDetailsService.username, UserDetailsService.defaultWorktype);
   }
   goToQuickNotes() {
      this.router.navigate([paths.worktime.rootPath, paths.worktime.quickNote]);
   }

   logOut() {
      this.sessionService.delete(undefined, (value: HttpResponse<any>) => {
         if (isResultValid(value) || value.status === httpCodes.forbidden || value.status === httpCodes.unauthorized) {
            UserDetailsService.loggedIn = false;
            UserDetailsService.permissionsForUser = null;
            this.router.navigate([paths.login]);
            this.redirect.setDefaultUrl(paths.login);
            localStorage.removeItem('urlAfterRedirects');
         }
      });
   }

   sidenavToggle() {
      this.sidenavToggled = false;
   }

   sidenavOpenOrToggle() {
      this.sidenavToggled = !this.sidenavToggled;
   }

   changeLanguage(language: Language) {
      const patchRequest: UsersLanguagePatchRequest = {
         username: UserDetailsService.username,
         userLang: language,
      };
      UserDetailsService.userLang = language;
      this.usersLanguageService.patch<unknown>('update', patchRequest, async (res) => {
         // Ha sikerült az adatbázisba írás
         if (res.status === httpCodes.created) {
            await languageSettingsStore(localStorage.getItem('translateVersion') || '1', this.langService, language);
            translateServiceSet(this.translate);
            // Visszajelzés a felhasználónak
            this.snackbar.openAlertMsg('The language has changed!', 'lightgreen', 5000);
            AppDateAdapter.setLocal(language.toUpperCase());
            this.translate.use(language);
            this.dateAdapter.setLocale(language);
         }
      });
   }
}
