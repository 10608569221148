export enum Language {
   HU = "hu",
   EN = "en",
   RO = "ro",
   PL = "pl",
   FR = "fr", // ft ügyféligény állapot megnevezések miatt, román
   FH = "fh", // ft ügyféligény állapot megnevezések miatt, magyar
}

export type TranslationGetRequest = { language: Language };

export type TranslationGetResponse = Record<string, string>;
