import { Language } from "@entities/api/translation.interfaces";

export function localeLanguageStore(userLang?: string) {
  let defaultLang: string;
  if (!userLang) {
    if (!localStorage.getItem("locale")) {
      for (const i of window.navigator.languages) {
        switch (i.toLowerCase()) {
          case Language.EN:
            defaultLang = Language.EN;
            break;
          case Language.HU:
            defaultLang = Language.HU;
            break;
          case Language.RO:
            defaultLang = Language.RO;
            break;
          case Language.FR:
            defaultLang = Language.FR;
            break;
          case Language.FH:
            defaultLang = Language.FH;
            break;
        }
        if (defaultLang) {
          break;
        }
      }
      if (!defaultLang) {
        defaultLang = Language.EN;
      }
      localStorage.setItem("locale", defaultLang);
      return true;
    }
  } else {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", userLang);
      return true;
    } else {
      if (userLang !== localStorage.getItem("locale")) {
        localStorage.setItem("locale", userLang);
        return true;
      }
    }
  }
}
