import { UserDetailsService } from "@commons/services";
import { paths } from "@config";
import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CanActivateService implements CanActivate {
  constructor(private router: Router) {}

  // tslint:disable-next-line:max-line-length
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): |boolean|UrlTree|Observable<boolean|UrlTree>|Promise<boolean|UrlTree> {
    if (UserDetailsService.loggedIn || UserDetailsService.forgottenPassword) {
      return true;
    }
    this.router.navigate([paths.login], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
