export const routes = {
  session: { route: "/session", secure: true },
  sessionCount: { route: "/session/3", secure: true },
  outInvoice: { route: "/outinvoices", secure: true },
  registry: { route: "/registry", secure: true },
  dictionary: { route: "/dictionary", secure: true },
  dictionaryQuery: { route: "/dictionaryquery", secure: true },
  inInvoice: { route: "/inInvoices", secure: true },
  users: { route: "/users", secure: true },
  userlist: { route: "/userlist", secure: true },
  emailList: { route: "/emaillist", secure: true },
  userDetails: { route: "/userdetails", secure: true },
  translation: { route: "/translation", secure: true },
  passwordReset: { route: "/passwordreset", secure: true },
  passwordChange: { route: "/passwordchange", secure: true },
  partners: { route: "/partners", secure: true },
  timesheet: { route: "/timesheet", secure: true },
  registeredWorktimeRecords: { route: "/registeredworktimerecords", secure: true },
  dailyWorktimeRecords: { route: "/dailyworktimerecords", secure: true },
  ownMonthlyWorktimeRecords: { route: "/ownmonthlyworktimerecords", secure: true },
  ownWorktimeCalculations: { route: "/ownworktimecalculations", secure: true },
  slidableDates: { route: "/slidabledates", secure: true },
  settings: { route: "/settings", secure: true },
  demand: { route: "/demand", secure: true },
  notice: { route: "/notice", secure: true },
  oneNotice: { route: "/onenotice", secure: true },
  demandHomePage: { route: "/demandhomepage", secure: true },
  demandList: { route: "/demandlist", secure: true },
  demandListForBookmark: { route: "/demandlistforbookmark", secure: true },
  partnerlist: { route: "/partnerlist", secure: true },
  systemVersion: { route: "/systemversion", secure: true },
  bookmark: { route: "/bookmark", secure: true },
  modifyBookmark: { route: "/modifybookmark", secure: true },
  demandTree: { route: "/demandtree", secure: true },
  onePartner: { route: "/onepartner", secure: true },
  quickNote: { route: "/quicknote", secure: true },
  oneQuickNote: { route: "/onequicknote", secure: true },
  dailyRegisteredWorks: { route: "/dailyregisteredworks", secure: true },
  holidays: { route: "/holidays", secure: true },
  ownHolidays: { route: "/ownholidays", secure: true },
  vacations: { route: "/vacations", secure: true },
  ownVacations: { route: "/ownvacations", secure: true },
  usersPartners: { route: "/userspartners", secure: true },
  usersLanguage: { route: "/userslanguage", secure: true },
  groups: { route: "/groups", secure: true },
  permissions: { route: "/permissions", secure: true },
  attachment: { route: "/upload", secure: true },
  documentations: { route: "/documentations", secure: true },
  ownpartnerlist: { route: "/ownpartnerlist", secure: true },
  usersEmailCopy: { route: "/usersemailcopy", secure: true },
  attachedDocuments: { route: "/attacheddocuments", secure: true },
  partnersUsers: { route: "/partnersusers", secure: true },
  modifiedDemands: { route: "/modifieddemands", secure: true },
  search: { route: "/search", secure: true },
  partnersUsersList: { route: "/partnersuserslist", secure: true },
  usersForPartnersList: { route: "/usersforpartnerslist", secure: true },
  usersForPartners: { route: "/usersforpartners", secure: true },
  usersVacationsCount: { route: "/usersvacationscount", secure: true },
  statByPartner: { route: "/statbypartner", secure: true },
  statByDemand: { route: "/statbydemand", secure: true },
  statWorktime: { route: "/statworktime", secure: true },
  statWorkDayByType: { route: "/statworkdaybytype", secure: true },
  statByUser: { route: "/statbyuser", secure: true },
  usersVacations: { route: "/usersvacations", secure: true },
  trHolidaysGenerated: { route: "/trholidaygenerated", secure: true },
  partnersForOrganizers: { route: "/partnersfororganizers", secure: true },
  usersForOrganizers: { route: "/usersfororganizers", secure: true },
  isoDocs: { route: "/isodocs", secure: true },
  statWorkDayMissingType: { route: "/statworkdaymissing", secure: true },
  ownWorkDayMissingType: { route: "/ownworkdaymissing", secure: true },
} as const;
