import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CountdownGlobalConfig, CountdownConfig } from 'ngx-countdown';
import { DialogComponent } from '@commons/components/dialog/dialog.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AppDateAdapter } from '@config';
import { registerLocaleData } from '@angular/common';
import localHU from '@angular/common/locales/hu';
import localRO from '@angular/common/locales/ro';
import localEN from '@angular/common/locales/en';
import localPL from '@angular/common/locales/pl';
import { ForgottenPasswordComponent } from './components/forgotten-password/forgotten-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { QuickNoteDialogComponent } from '@commons/components/quick-note-dialog/quick-note-dialog.component';
import { CommonsModule } from '@commons/commons.module';
import { SharedModule } from './shared/shared.module';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { QuillModule } from 'ngx-quill';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchComponent } from './components/search/search.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HighlightSearch } from './components/search/highlight.pipe';
import { TranslationsComponent } from './components/translations/translations.component';
import { UserService } from './services/users.service';
import { PartnerService } from './services/partner.service';
import { DictionaryService } from './services/dictionary.service';
import { PartnerUsersService } from './services/partner-users.service';
import { NgxTimerModule } from './ngx-timer/ngx-timer.module';
import { WipDemandListComponent } from './wip-demand-list/wip-demand-list.component';
import { WipDemandsResponsibleComponent } from './wip-demands-responsible/wip-demands-responsible.component';
import { WipDemandsUserSelectorComponent } from './wip-demands-user-selector/wip-demands-user-selector.component';
import { WipDemandsArrangerComponent } from './wip-demands-arranger/wip-demands-arranger.component';

registerLocaleData(localHU);
registerLocaleData(localRO);
registerLocaleData(localEN);
registerLocaleData(localPL);

export function countdownConfigFactory(): CountdownConfig {
   return { format: `HH:mm:ss` };
}

@NgModule({
   declarations: [
      AppComponent,
      LoginComponent,
      HomeComponent,
      SettingsComponent,
      ForgottenPasswordComponent,
      ChangePasswordComponent,
      BookmarksComponent,
      SearchComponent,
      HighlightSearch,
      TranslationsComponent,
      WipDemandListComponent,
      WipDemandsResponsibleComponent,
      WipDemandsUserSelectorComponent,
      WipDemandsArrangerComponent,
   ],
   imports: [
      // MainModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      SharedModule,
      TranslateModule.forRoot(),
      QuillModule.forRoot(),
      AppRoutingModule,
      CommonsModule,
      NgxSpinnerModule,
      MatProgressSpinnerModule,
      NgxTimerModule,
   ],
   exports: [],
   providers: [
      CookieService,
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpInterceptorService,
         multi: true,
      },
      { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
      {
         provide: DateAdapter,
         useClass: AppDateAdapter,
         deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: LOCALE_ID, useValue: 'ro-RO' },
      { provide: LOCALE_ID, useValue: 'hu-HU' },
      { provide: LOCALE_ID, useValue: 'en-GB' },
      { provide: LOCALE_ID, useValue: 'pl-PL' },
      DeviceDetectorService,
      TranslateService,
      UserService, //TODO a megfelelő moduleba importálni/exportálni
      PartnerService, //TODO a megfelelő moduleba importálni/exportálni
      DictionaryService, //TODO a megfelelő moduleba importálni/exportálni
      PartnerUsersService, //TODO a megfelelő moduleba importálni/exportálni
   ],
   entryComponents: [DialogComponent, QuickNoteDialogComponent],
   bootstrap: [AppComponent],
})
export class AppModule {}
