import { UserDetailsService } from "@commons/services";
import { paths } from "@config";
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../commons/services/loader/loader.service";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { finalize, catchError } from "rxjs/operators";
import { httpCodes, Redirect } from "@ha8rt/http.service";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  redirect: Redirect;

  constructor(
    private cookieService: CookieService,
    private Loader: LoaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.redirect = new Redirect(this.router, this.route);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      finalize(() => {
        let val = this.cookieService.get("expiry");
        if (val.startsWith(`j:"`)) {
          val = val.replace(`j:"`, "");
        }
        if (val.endsWith(`""`)) {
          val = val.slice(0, val.length - 1);
        }
        this.Loader.update(Number.parseInt(val, 10));
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status && error.status === httpCodes.unauthorized) {
            UserDetailsService.loggedIn = false;
            this.router.navigate([paths.login]);
            this.redirect.setDefaultUrl(paths.login);
            localStorage.removeItem("urlAfterRedirects");
          }
        }
        return throwError(error);
      })
    );
  }
}
