import { NativeDateAdapter } from "@angular/material";
import * as _moment from "moment";
export const szegedswLogo = "assets/logologo2.png";
export const testSzegedswLogo = "assets/logologo_piros.png";
export const szegedswLogoInverse = "assets/szegedswlogoinverz.jpg";
export const returnUrlName = "returnUrl";
export const defaltUrl = "";
import { formatDate } from "@angular/common";

export const config: { [key: string]: AppDateConfig } = {
  HU: {
    dateDisplayFormat: "yyyy/MM/dd",
    dateLocal: "hu-HU",
  },
  RO: {
    dateDisplayFormat: "dd/MM/yyyy",
    dateLocal: "ro-RO",
  },
  EN: {
    dateDisplayFormat: "dd/MM/yyyy",
    dateLocal: "en-GB",
  },
  PL: {
    dateDisplayFormat: "dd/MM/yyyy",
    dateLocal: "pl-PL",
  }
};

export class AppDateAdapter extends NativeDateAdapter {
  public static appDateConfig: AppDateConfig = config.HU;

  public static setLocal(lang: keyof typeof config) {
    AppDateAdapter.appDateConfig = config[lang];
  }

  format(date: Date): string {
    const val = formatDate(
      date,
      AppDateAdapter.appDateConfig.dateDisplayFormat,
      AppDateAdapter.appDateConfig.dateLocal
    );
    return val;
  }

  getFirstDayOfWeek(): number {
    return 1;
   }
}

export interface AppDateConfig {
  dateDisplayFormat: string;
  dateLocal: keyof typeof ProvidedLocales;
}

export enum ProvidedLocales {
  "hu-HU",
  "en-GB",
  "ro-RO",
  "pl-PL",
}
