import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@commons/components/userlist-selector/userlist-selector.component';

@Component({
  selector: 'wip-demands-user-selector',
  templateUrl: './wip-demands-user-selector.component.html',
  styleUrls: ['./wip-demands-user-selector.component.scss']
})
export class WipDemandsUserSelectorComponent implements OnInit {

  @Input('users')
  users :User[]= [];

  @Input('selected')
  selectedUsers : User[] = [];

  @Input('placeholder')
  placeholder : string;
  
  @Output('clickEmitter')
  clickEmitter: EventEmitter<User[]> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClicked() {
    this.clickEmitter.emit(this.selectedUsers);
  }

}
