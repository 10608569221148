import { UserDetailsService, SetTitleService, SnackbarService } from "@commons/services";
import { BookmarksModifyType, DemandListForBookmarkResponse } from "@entities/api/demand.interface";
import { DictionaryEntry, dictionaryQuery } from "@commons/functions/dictionary.query";
import { PartnerlistGetResponse } from "@entities/api/partnerlist.interface";
import { BookmarksGetResponse, ChangedBookmarks } from "@entities/api/bookmark.interface";
import { MatSort, MatTableDataSource } from "@angular/material";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { httpCodes, HttpService } from "@ha8rt/http.service";
import * as XLSX from "xlsx";
import { routes } from "@config";

type TableRowData = BookmarksGetResponse[number];
type TableRowDataForRecording = DemandListForBookmarkResponse[number];
interface Groups {name: string; }

@Component({
  selector: "app-bookmarks",
  templateUrl: "./bookmarks.component.html",
  styleUrls: ["./bookmarks.component.scss"]
})
export class BookmarksComponent implements OnInit {
  dataSource: MatTableDataSource<TableRowData>;
  displayedRecords: TableRowData[] = [];
  displayedRecordsForRecording: TableRowDataForRecording[] = [];
  displayedFirst = UserDetailsService.mobile ? ["demandLabel", "demandState", "name"] :
    ["demandLabel", "demandState", "name", "deadline", "demandUrgency", "demandKind", "demandAccounting", "askedPerson"];
  displayedFirstFP = this.mobile ? ["demandLabel", "demandState", "name"] :
  ["demandLabel", "demandState", "name", "demandKind", "askedPerson", "estimatedWt"];
  displayedSecond = UserDetailsService.mobile ? ["partner", "demandUrgency", "dateOfLastUpdate"] :
    ["partner", "demandStatus", "arranger", "responsible", "bmi", "ami", "estimatedWt", "creationDate", "dateOfLastUpdate"];
  displayedSecondFP = ["demandKind", "askedPerson", "estimatedWt"];
  fileName = "Bookmarks.xlsx";
  bookmarkService: HttpService;
  partnerListService: HttpService;
  dictionaryQueryService: HttpService;
  demandListService: HttpService;
  selectableStates: DictionaryEntry[] = [];
  states: Groups[] = [];
  statuses: Groups[] = [];
  urgencies: Groups[] = [];
  kinds: Groups[] = [];
  accountings: Groups[] = [];
  partners: Groups[] = [];
  isRecording = false;
  selectedPartner = "%";
  selectedState = "Under process";
  partnerList: PartnerlistGetResponse;
  listedPartners: BookmarksModifyType[] = [];
  changedBookmarks: ChangedBookmarks[] = [];
  expansionOpened = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  get mobile() { return UserDetailsService.mobile; }
  get colleague() { return UserDetailsService.isWorker; }

  constructor(private http: HttpClient, private snackbar: SnackbarService, private title: SetTitleService) {
    this.partnerListService = new HttpService(this.http).setApi(routes.ownpartnerlist);
    this.bookmarkService = new HttpService(this.http).setApi(routes.bookmark);
    this.dictionaryQueryService = new HttpService(this.http).setApi(routes.dictionaryQuery);
    this.demandListService = new HttpService(this.http).setApi(routes.demandListForBookmark);
  }

  ngOnInit() {
    this.title.setTitle("Bookmarks");
    this.selectData();
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
      data[sortHeaderId] !== null ? (typeof data[sortHeaderId] === "boolean" || typeof data[sortHeaderId] === "number") ?
        data[sortHeaderId] : data[sortHeaderId].toLocaleLowerCase() : 0;
  }

  filterChange(filterId, filterValue) {
    const filterObj = [];
    filterObj.push({
      id: filterId,
      value: filterValue
    });
    if (this.dataSource.filter.length > 0) {
      this.dataSource.filter = JSON.stringify(JSON.parse(this.dataSource.filter).filter(
        (item) => item.id !== filterId
      ));
    }
    if (filterValue !== "") {
      if (this.dataSource.filter.length > 0) {
        this.dataSource.filter = JSON.stringify(JSON.parse(this.dataSource.filter).concat(filterObj));
      } else {
        this.dataSource.filter = JSON.stringify(filterObj);
      }
    }
  }

  applyFilter(filterId, event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterObj = [];
    filterObj.push({
      id: filterId,
      value: filterValue
    });
    if (this.dataSource.filter.length > 0) {
      this.dataSource.filter = JSON.stringify(JSON.parse(this.dataSource.filter).filter(
        (item) => item.id !== filterId
      ));
    }
    if (filterValue !== "") {
      if (this.dataSource.filter.length > 0) {
        this.dataSource.filter = JSON.stringify(JSON.parse(this.dataSource.filter).concat(filterObj));
      } else {
        this.dataSource.filter = JSON.stringify(filterObj);
      }
    }
  }

  recordingBookmarks() {
    dictionaryQuery("demand-state", this.selectableStates, this.dictionaryQueryService);
    this.partnerListService.get<PartnerlistGetResponse>(undefined, (res) => {
      this.partnerList = res.body;
      if (this.colleague === false) {
        this.selectedPartner = res.body[0].name;
      }
      this.isRecording = true;
    });
  }

  listDemands() {
    this.listedPartners = [];
    this.demandListService.get<DemandListForBookmarkResponse>(
      {partnerName: this.selectedPartner, demandState: this.selectedState, username: UserDetailsService.username},
    async (res) => {
      if (res.body && res.body.length > 0) {
        this.displayedRecordsForRecording = res.body;
        this.displayedRecordsForRecording = this.displayedRecordsForRecording.sort((a, b) =>
        a.partner < b.partner ? -1 : a.partner > b.partner ? 1 : 0);
        this.displayedRecordsForRecording.forEach(item => {
          if (this.listedPartners.length === 0) {
            this.listedPartners.push({name: item.partner, data: [item]});
          } else {
            const objIndex = this.listedPartners.findIndex((obj => obj.name === item.partner));
            if (objIndex === -1) {
              this.listedPartners.push({name: item.partner, data: [item]});
            } else {
              this.listedPartners[objIndex].data.push(item);
            }
          }
        });
      }
    });
  }

  changeBookmark(demandLabel) {
    if ((this.changedBookmarks.some(item => item.demandLabel === demandLabel)) === true) {
      this.changedBookmarks = this.changedBookmarks.filter(item => (item.demandLabel !== demandLabel));
    } else {
      this.changedBookmarks.push({demandLabel});
    }
  }

  modifyBookmarks() {
    this.bookmarkService.patch<unknown>("update", {demandLabels: this.changedBookmarks, username: UserDetailsService.username}, (res) => {
      if (res.status === httpCodes.created) {
        // Újra felolvassuk az adatokat
        this.selectData();
        // Visszajelzés a felhasználónak
        this.snackbar.openAlertMsg(
          "Successfully modified!",
          "yellow",
          5000
        );
        // A beviteli részt visszaállítjuk üresre
        this.isRecording = false;
        this.changedBookmarks = [];
        this.listedPartners = [];
        this.selectedPartner = "%";
        this.selectedState = "Under process";
      }
    });
  }

  selectData() {
    this.dataSource = new MatTableDataSource(this.displayedRecords);
    this.dataSource.filterPredicate = (data: TableRowData, filtersJson: string) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);
      filters.forEach(filter => {
        const val = data[filter.id] === null ? "" : data[filter.id];
        matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
      });
      return matchFilter.every(Boolean);
    };
    this.dataSource.sort = this.sort;
    this.bookmarkService.get<BookmarksGetResponse>({username: UserDetailsService.username}, (res) => {
      if (res.status === httpCodes.created && res.body.length) {
        this.displayedRecords = res.body;
        this.dataSource.data = this.displayedRecords;
        // Szűrők feltöltése
        this.displayedRecords.forEach(item => {
          this.states.push({name: item.demandState});
          this.urgencies.push({name: item.demandUrgency});
          this.statuses.push({name: item.demandStatus});
          this.kinds.push({name: item.demandKind});
          this.accountings.push({name: item.demandAccounting});
          this.partners.push({name: item.partner});
        });
        // Szűrőkben a duplikátumok törlése
        this.states = this.states.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
        this.urgencies = this.urgencies.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
        this.statuses = this.statuses.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
        this.kinds = this.kinds.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
        this.accountings = this.accountings.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
        this.accountings = this.accountings.filter(item => item.name !== null);
        this.partners = this.partners.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
      }
    });
  }

  exportexcel(): void {
    // table id is passed over here
    const element = document.getElementById("bookmarks-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws["!cols"][0] = { hidden: true };
    // generate workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // save to file
    XLSX.writeFile(wb, this.fileName);
  }
}
