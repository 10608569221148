import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@ha8rt/http.service';
import { routes, paths } from '@config';
import { DemandGetResponseForModify, DemandPatchRequest } from '@entities/api/demand.interface';

@Injectable({
   providedIn: 'root',
})
export class DemandService {
   demandService: HttpService;
   dictionaryQueryService: HttpService;
   partnersUsersListService: HttpService;
   partnerListService: HttpService;
   demandListService: HttpService;

   constructor(private http: HttpClient) {
      this.demandService = new HttpService(this.http).setApi(routes.demand);
      this.demandListService = new HttpService(this.http).setApi(routes.demandList);

      this.dictionaryQueryService = new HttpService(this.http).setApi(routes.dictionaryQuery);
      this.partnersUsersListService = new HttpService(this.http).setApi(routes.partnersUsersList);
   }

   public async getDemandData(demandLabel: string): Promise<DemandGetResponseForModify> {
      const result = await this.demandService
         .get<DemandGetResponseForModify>({ demandLabel: demandLabel })
         .toPromise()
         .then((r) => r.body);
      return result;
   }

   public async updateDemandData(data: DemandPatchRequest) {
      const result = await this.demandService.patch<string>('', data).toPromise();
      return result;
   }

   public async deleteDemand(demandLabel: string) {
      const result = await this.demandService.put<unknown>({ demandLabel }).toPromise();
      return result;
   }

   public async getWipDemandList(responsible?: string[], arrangers?: string[]) {
      return this.demandListService.post<WipDemand[]>({responsible, arrangers}).toPromise();
   }

}

export type WipDemand = {   demandLabel: string;
   demandName: string;
   arrangers: { firstname: string; lastname: string }[];
   status: string; 
   responsible: { firstname: string; lastname: string };
}