import { UserDetailsService, QuickMenuService, SetTitleService, SnackbarService, TimerService } from "@commons/services";
import { TranslateService } from "@ngx-translate/core";
import { szegedswLogo, returnUrlName, defaltUrl, paths, routes, AppDateAdapter } from "@config";
import { AppComponent } from "../../app.component";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpService, httpCodes, isResultValid } from "@ha8rt/http.service";
import { ModalHandler } from "@ha8rt/modal";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { asyncSome, hasPermission, languageSettingsStore, translateServiceSet } from "@commons/functions";
import {
  SessionGetRequest,
  SessionGetResult,
  SessionPostRequest,
  SessionPostResponse,
} from "@entities/api/session.interfaces";
import { Permission, roles } from "@entities/permissions.enum";
import { OwnHolidays } from "@entities/api/holidays.interface";
import { OwnHolidaysService } from "src/app/worktime/services/own-holidays/own-holidays.service";
import { UserlistGetResponse } from "@entities/api/userlist.interfaces";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  logo = szegedswLogo;
  service: HttpService;
  langService: HttpService;
  userListService: HttpService;
  ownHolidaysService: HttpService;
  modal = new ModalHandler();
  hidePassword = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private timer: TimerService,
    private title: SetTitleService,
  ) {
    this.service = new HttpService(this.http).setApi(routes.session);
    this.langService = new HttpService(this.http).setApi(routes.translation);
    this.userListService = new HttpService(this.http).setApi(routes.userlist);
    this.ownHolidaysService = new HttpService(this.http).setApi(routes.ownHolidays);
  }

  ngOnInit() {
    this.title.setTitle("Login");
    if (AppComponent.isFirstRefresh) {
      const getRequest: SessionGetRequest = {};
      this.service.get<SessionGetResult>(getRequest, async (res) => {
        if (isResultValid(res)) {
          UserDetailsService.permissionsForUser = res.body.permission;
          await this.userDetailsUpload(res.body);
          await languageSettingsStore(
            res.body.paramValue,
            this.langService,
            res.body.userLang
          );
          translateServiceSet(this.translate);
          this.redirect();
          if (hasPermission(Permission.funcQuickNote)) {
            this.timer.timerSet(UserDetailsService.username);
          }
        } else {
          await languageSettingsStore(
            localStorage.getItem("translateVersion") || "1",
            this.langService,
            localStorage.getItem("locale") || undefined
          );
          translateServiceSet(this.translate);
        }
      });
    }
  }

  loginSubmit(form: NgForm) {
    const credential: SessionPostRequest = {
      username: form.value.username,
      password: form.value.password,
    };
    this.service.post<SessionPostResponse>(credential, async (res) => {
      if (res.status === httpCodes.created) {
        await languageSettingsStore(
          res.body.paramValue,
          this.langService,
          res.body.userLang
        );
        translateServiceSet(this.translate);
        UserDetailsService.permissionsForUser = res.body.permission;
        if (res.body.passwordState !== 0) {
          UserDetailsService.forgottenPassword = true;
          AppComponent.urlAfterRedirects = paths.changePassword;
          this.router.navigate([paths.changePassword]);
          this.snackbar.openAlertMsg(
            "You have previously requested password generation, so you need to change the generated password!",
            "yellow",
            10000,
            undefined,
            "top"
          );
        } else if (res.body.passwordState === 0) {
          await this.userDetailsUpload(res.body);
          this.router.navigate([paths.home]);
          this.redirect();
          if (hasPermission(Permission.funcQuickNote)) {
            this.timer.timerSet(UserDetailsService.username);
          }
        }
      } else if (res.status === httpCodes.unauthorized) {
        form.resetForm();
        this.snackbar.openAlertMsg("Wrong username or password!", "red", 5000);
      } else if (res.status === httpCodes.forbidden) {
        form.resetForm();
        this.snackbar.openAlertMsg(
          `You don't have permission to perform this operation!`,
          "red",
          10000
        );
      }
    });
  }

  toForgottenPassword() {
    UserDetailsService.forgottenPassword = true;
    this.router.navigate([paths.forgottenPassword]);
  }

  redirect() {
    let url =
      this.route.snapshot.queryParamMap.get(returnUrlName) ||
      AppComponent.urlAfterRedirects ||
      defaltUrl;
    const queryParams = {};
    new URLSearchParams(url.split("?")[1]).forEach((value, key, parent) => {
      queryParams[key] = value;
    });
    url = url.split("?")[0];
    if (url === "/" + paths.login) {
      url = paths.home;
    }
    const plannedUrl = this.router.url.match("worktime%2FplannedHolidays");
    if (plannedUrl !== null) {
      this.userListService.get<UserlistGetResponse>({szegedswWorker: true, active: true}, (res) => {
        OwnHolidaysService.colleagues = res.body;
        this.ownHolidaysService.get<OwnHolidays>(undefined, (resp) => {
          if (resp.status === httpCodes.created && resp.body.length) {
            OwnHolidaysService.defaultHolidays = resp.body;
          }
          this.router.navigate([url], { queryParams });
        });
      });
    } else {
      this.router.navigate([url], { queryParams });
    }
  }

  async userDetailsUpload(body: SessionGetResult) {
    AppDateAdapter.setLocal(body.userLang.toUpperCase());
    UserDetailsService.loggedIn = true;
    UserDetailsService.firstname = body.firstname;
    UserDetailsService.lastname = body.lastname;
    UserDetailsService.username = body.username;
    UserDetailsService.database = body.database;
    UserDetailsService.location = body.location;
    UserDetailsService.allUsersWt = body.allUsersWt || false;
    UserDetailsService.defaultWorktype = body.defaultWorktype || "";
    UserDetailsService.canSeeAutNotices = body.canSeeAutNotices || false;
    UserDetailsService.firstNoticeOnTop = body.firstNoticeOnTop || false;
    UserDetailsService.isWorker = body.isWorker || false;
    UserDetailsService.canTranslate = await hasPermission(Permission.permCanTranslate);
    UserDetailsService.userLang = body.userLang;

    QuickMenuService.timesheetMenu = await hasPermission(Permission.funcTimesheet);
    QuickMenuService.quickNoteMenu = await hasPermission(Permission.funcQuickNote);
    QuickMenuService.demandHomePageMenu = await hasPermission(Permission.funcDemandHomePage);
    QuickMenuService.newDemandMenu = await hasPermission(Permission.funcNewDemand);
    QuickMenuService.modifiedDemandsMenu = await hasPermission(Permission.funcModifiedDemands);
    QuickMenuService.usersMaintenanceMenu = await hasPermission(Permission.funcUserMaintenance);
    QuickMenuService.clientsMaintenanceMenu = await hasPermission(Permission.funcClientMaintenance);
    QuickMenuService.translationsMenu = await hasPermission(Permission.funcTranslations);
    QuickMenuService.attachedDocumentsMenu = await hasPermission(Permission.funcAttachedDocuments);
    QuickMenuService.documentationsMenu = await hasPermission(Permission.funcDocumentations);
    QuickMenuService.sysAdminMenu = await asyncSome(roles.sysadmin.permissions, hasPermission);
    QuickMenuService.statisticsMenu = await asyncSome(roles.statistics.permissions, hasPermission);
    QuickMenuService.officesMenu = await asyncSome(roles.offices.permissions, hasPermission);
    QuickMenuService.isoMenu = await asyncSome(roles.iso.permissions, hasPermission);
    QuickMenuService.permCanBrowseUsersWorktime = await hasPermission(Permission.permCanBrowseUsersWorktime);
  }
}
