import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-not-implemented-yet",
  templateUrl: "./not-implemented-yet.component.html",
  styleUrls: ["./not-implemented-yet.component.scss"]
})
export class NotImplementedYetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
