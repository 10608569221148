import { SnackBarContent } from "../../components/mysnackbar/mysnackbar.component";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { MysnackbarComponent } from "@commons/components/mysnackbar/mysnackbar.component";
import { MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  constructor(private snackbar: MatSnackBar) {}

  public openAlertMsg(
    msg: string,
    color: string = "red",
    durationMS: number,
    textAlign?: string,
    verticalAlign?: MatSnackBarVerticalPosition
  ) {
    textAlign = textAlign || "center";
    // verticalAlign = verticalAlign || "bottom";
    this.verticalPosition = verticalAlign || "bottom";
    const content: SnackBarContent = {
      msg,
      color,
      textAlign,
    };
    this.snackbar.openFromComponent(MysnackbarComponent, {
      data: content,
      duration: durationMS,
      verticalPosition: this.verticalPosition,
    });
  }
}
