import { HttpService } from "@ha8rt/http.service";
import { translationQuery } from "./translation.query";
import { localeLanguageStore } from "./locale-language.store";
import { translateVersionStore } from "./translate-version.store";

export async function languageSettingsStore(
  translateParamValue: string,
  langService: HttpService,
  userLang?: string,
) {
  let getTranslation = localeLanguageStore(userLang || undefined);
  getTranslation = translateVersionStore(translateParamValue) || getTranslation;
  if (getTranslation) {
    await translationQuery(localStorage.getItem("locale"), langService);
  }
}
