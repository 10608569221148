import { Injectable, EventEmitter } from "@angular/core";
import { Routes } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class EventService {

  ModuleLoadedEvent = new EventEmitter<Routes>();
  constructor() { }
}
