import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
   MatCardModule,
   MatFormFieldModule,
   MatInputModule,
   MatMenuModule,
   MatListModule,
   MatSidenavModule,
   MatDatepickerModule,
   MatIconModule,
   MatSelectModule,
   MatTableModule,
   MatSortModule,
   MatButtonModule,
   MatNativeDateModule,
   MatToolbarModule,
   MatExpansionModule,
   MatSnackBarModule,
   MatDialogModule,
   MatCheckboxModule,
   MatChipsModule,
   MatAutocompleteModule,
   MatTooltipModule,
   MatRadioModule,
   MatTreeModule,
   MatTabsModule,
   MatProgressSpinnerModule,
   MatDividerModule,
} from '@angular/material';
import { CountdownModule } from 'ngx-countdown';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { FileUploadModule } from 'ng2-file-upload';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { AppDateAdapter } from '@config';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
   declarations: [],
   imports: [
      QuillModule.forRoot(),
      FormsModule,
      ReactiveFormsModule,
      TranslateModule.forChild(),
      CommonModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      MatMenuModule,
      MatListModule,
      MatSidenavModule,
      MatIconModule,
      MatSelectModule,
      MatDatepickerModule,
      MatTableModule,
      MatSortModule,
      MatButtonModule,
      MatNativeDateModule,
      MatToolbarModule,
      MatExpansionModule,
      MatSnackBarModule,
      CountdownModule,
      MatDialogModule,
      MatCheckboxModule,
      MatChipsModule,
      MatAutocompleteModule,
      MatTooltipModule,
      MatRadioModule,
      MatTreeModule,
      CalendarModule,
      FileUploadModule,
      MatTabsModule,
      MatProgressSpinnerModule,
      MatDividerModule,
   ],
   exports: [
      QuillModule,
      ReactiveFormsModule,
      FormsModule,
      TranslateModule,
      CommonModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      MatMenuModule,
      MatListModule,
      MatSidenavModule,
      MatIconModule,
      MatSelectModule,
      MatDatepickerModule,
      MatTableModule,
      MatSortModule,
      MatButtonModule,
      MatNativeDateModule,
      MatToolbarModule,
      MatExpansionModule,
      MatSnackBarModule,
      CountdownModule,
      MatDialogModule,
      MatCheckboxModule,
      MatChipsModule,
      MatAutocompleteModule,
      MatTooltipModule,
      MatRadioModule,
      MatTreeModule,
      CalendarModule,
      FileUploadModule,
      MatTabsModule,
      MatProgressSpinnerModule,
      MatDividerModule,
   ],
   providers: [
      {
         provide: DateAdapter,
         useClass: AppDateAdapter,
         deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: LOCALE_ID, useValue: 'ro-RO' },
      { provide: LOCALE_ID, useValue: 'hu-HU' },
      { provide: LOCALE_ID, useValue: 'en-GB' },
      { provide: LOCALE_ID, useValue: 'pl-PL' },
   ],
})
export class SharedModule {}
