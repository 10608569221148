import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@ha8rt/http.service';
import { routes, paths } from '@config';
import { UserlistGetResponse } from '@entities/api/userlist.interfaces';

@Injectable({
   providedIn: 'root',
})
export class UserService {
   private userListForEmailService: HttpService;

   constructor(private http: HttpClient) {
      this.userListForEmailService = new HttpService(this.http).setApi(routes.userlist);
   }

   public async getUserList(szegedswWorker: boolean, active = true) {
      return this.userListForEmailService
         .get<UserlistGetResponse>({ szegedswWorker: true, active: true })
         .toPromise()
         .then((r) => r.body);
      //TODO error handling
   }
}
