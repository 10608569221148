import { IDialogData } from "../components/dialog/dialog.component";
import { MatDialogConfig } from "@angular/material";

export class DialogDataBinder extends MatDialogConfig<IDialogData> {
  public data: IDialogData = {};
  // tslint:disable-next-line: max-line-length
  constructor(
    public headerText?: string,
    public headerParams?: object,
    public contentText?: string,
    public contentParams?: object,
    public firstButton?: DialogButtonClass,
    public secondButton?: DialogButtonClass,
    public thirdButton?: DialogButtonClass
  ) {
    super();
    if (headerText) {
      this.data.header = headerText;
    }
    this.data.headerParameters = headerParams || undefined;
    if (contentText) {
      this.data.content = contentText;
    }
    this.data.contentParameters = contentParams || undefined;
    if (firstButton) {
      this.data.firstButtonObj = firstButton;
    }
    if (secondButton) {
      this.data.secondButtonObj = secondButton;
    }
    if (thirdButton) {
      this.data.thirdButtonObj = thirdButton;
    }
  }
}

export class DialogButtonClass {
  constructor(public text: string, public event?: number) {}
}
