import { TranslationsComponent } from "./components/translations/translations.component";
import { SearchComponent } from "./components/search/search.component";
import { BookmarksComponent } from "./components/bookmarks/bookmarks.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ForgottenPasswordComponent } from "./components/forgotten-password/forgotten-password.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { paths } from "@config";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Permission, roles } from "@entities/permissions.enum";
import { AuthGuardService, NeverActivateGuardService } from "@commons/services";
import { CanActivateService } from "./services/can-activate/can-activate.service";
import { CommonModule } from "@angular/common";
import { WipDemandsResponsibleComponent } from "./wip-demands-responsible/wip-demands-responsible.component";
import { WipDemandsArrangerComponent } from "./wip-demands-arranger/wip-demands-arranger.component";

const routes: Routes = [
  { path: paths.login, component: LoginComponent },
  { path: paths.forgottenPassword, component: ForgottenPasswordComponent },
  { path: paths.changePassword, component: ChangePasswordComponent },
  {
    path: paths.home,
    component: HomeComponent,
    canActivate: [CanActivateService],
  },
  {
    path: paths.settings,
    component: SettingsComponent,
    canActivate: [CanActivateService],
  },
  {
    path: paths.search,
    component: SearchComponent,
    canActivate: [CanActivateService],
  },
  {
    path: paths.bookmarks,
    component: BookmarksComponent,
    canActivate: [AuthGuardService],
    data: {
      secured: [Permission.funcBookmarks]
    }
  },
  {
    path: paths.translations,
    component: TranslationsComponent,
    canActivate: [AuthGuardService],
    data: {
      secured: [Permission.funcTranslations]
    }
  },
  {
    path: paths.usersGuide.rootPath,
    children: [
      {
        path: "",
        canActivate: [NeverActivateGuardService],
        redirectTo: "",
        pathMatch: "full",
      },
      {
        path: paths.usersGuide.first,
        redirectTo: paths.notImplementedYet,
        canActivate: [AuthGuardService],
        data: {
          secured: [Permission.funcUsersGuide]
        }
      },
      {
        path: paths.usersGuide.second,
        redirectTo: paths.notImplementedYet,
        canActivate: [AuthGuardService],
        data: {
          secured: [Permission.funcUsersGuide]
        }
      },
    ]
  },
  {
    path: paths.group1,
    redirectTo: paths.notImplementedYet,
    canActivate: [NeverActivateGuardService],
    data: {
      secured: [
        Permission.funcTimesheet,
        Permission.funcQuickNote,
        Permission.funcBookmarks,
        Permission.funcDemandHomePage,
        Permission.funcNewDemand,
        Permission.funcUserMaintenance,
        Permission.funcClientMaintenance,
        Permission.funcTranslations,
        Permission.funcAttachedDocuments,
        Permission.funcDocumentations,
        Permission.funcUsersGuide,
      ]
    }
  },
  {
    path: paths.sysadmin.rootPath,
    loadChildren: () => import("./sysadmin/sysadmin.module").then(m => m.SysadminModule),
    data: { secured: roles.sysadmin.permissions }
  },
  {
    path: paths.offices.rootPath,
    loadChildren: () => import("./offices/offices.module").then(m => m.OfficesModule) ,
    data: { secured: roles.offices.permissions }
  },
  {
    path: paths.worktime.rootPath,
    loadChildren: () => import("./worktime/worktime.module").then(m => m.WorktimeModule),
    data: { secured: roles.worktime.permissions }
  },
  {
    path: paths.statistics.rootPath,
    loadChildren: () => import("./statistics/statistics.module").then(m => m.StatisticsModule),
    data: { secured: roles.statistics.permissions }
  },
  {
    path: paths.demand.rootPath,
    loadChildren: () => import("./demand/demand.module").then(m => m.DemandModule),
    data: { secured: roles.demand.permissions }
  },
  {
    path: paths.iso.rootPath,
    loadChildren: () => import("./iso/iso.module").then(m => m.IsoModule),
    data: { secured: roles.iso.permissions }
  },
  {
    path: paths.clientOrganization.rootPath,
    loadChildren: () => import("./client-organization/client-organization.module").then(m => m.ClientOrganizationModule),
    data: { secured: roles.clientOrganization.permissions }
  },

  { path: paths.wipDemandListResponsible, 
    component: WipDemandsResponsibleComponent,
    canActivate: [AuthGuardService], 
    data: { secured: [Permission.funcWipDemandsResponsible] } 
  },
  { path: paths.wiDemandListArranger, 
    component: WipDemandsArrangerComponent,
    canActivate: [AuthGuardService], 
    data: { secured: [Permission.funcWipDemandsArranger] } 
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
