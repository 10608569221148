export function translateVersionStore(translateVersion: string) {
  const trVersion = "translateVersion";
  if (!localStorage.getItem(trVersion)) {
    localStorage.setItem(trVersion, translateVersion);
    return true;
  }
  if (localStorage.getItem(trVersion) !== translateVersion) {
    localStorage.setItem(trVersion, translateVersion);
    return true;
  }
  return false;
}
