import { Component, OnInit } from '@angular/core';
import { User } from '@commons/components/userlist-selector/userlist-selector.component';
import { DemandService, WipDemand } from '../services/demand.service';
import { UserService } from '../services/users.service';
import { UserDetailsService } from '@commons/services';

@Component({
  selector: 'app-wip-demands-arranger',
  templateUrl: './wip-demands-arranger.component.html',
  styleUrls: ['./wip-demands-arranger.component.scss']
})
export class WipDemandsArrangerComponent implements OnInit {

  get mobile() { return UserDetailsService.mobile; }

  users :User[]= [];
  selectedUsers : User[] = [];
  demands : WipDemand[] = [];

  constructor(private userService: UserService, private demandService: DemandService) { }

  ngOnInit() {
    this.refreshUsersList().then(() => this.getWipDemands());

  }

  async refreshUsersList() {
    this.users = await this.userService.getUserList(true,true);
    this.selectedUsers = [this.users.find((u) => u.username === UserDetailsService.username)];

  }
  async getWipDemands() {
    const users = this.selectedUsers.map(u=> u.username);
    this.demands = (await this.demandService.getWipDemandList(undefined,users)).body;
  }

  async onRefreshData(selectedUsersInChildren: User[]) {
    this.selectedUsers = selectedUsersInChildren;
    this.getWipDemands();
  }

}
