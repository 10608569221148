import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  static loading: boolean;
  loadNum = new Subject<number>();
  currValue: number;

  constructor() {
    this.loadNum.subscribe((value) => {
      this.currValue = value;
    });
  }

  get loading() { return LoaderService.loading; }

  update(num: number) {
    this.loadNum.next(num);
  }
}
