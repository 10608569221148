import { isNullOrUndefined } from "util";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class SetTitleService {
  constructor(private titleService: Title) {}

  public setTitle(title: string) {
    const translates = JSON.parse(localStorage.getItem("translation"));
    if (translates !== undefined && translates !== null) {
      if (translates[title] !== undefined && translates[title] !== null) {
        this.titleService.setTitle(`Irokez - ${translates[title]}`);
      }
      this.titleService.setTitle(`Irokez - ${title}`);
    } else {
      this.titleService.setTitle(`Irokez - ${title}`);
    }
    /* if (isNullOrUndefined(translates) || (!(isNullOrUndefined(translates)) && isNullOrUndefined(translates[title]) )) {
      this.titleService.setTitle(`Irokez - ${title}`);
    } else {
      this.titleService.setTitle(`Irokez - ${translates[title]}`);
    } */
  }
}
