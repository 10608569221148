import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { paths } from "@config";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { NotImplementedYetComponent } from "./components/not-implemented-yet/not-implemented-yet.component";

const routes: Routes = [
{
  path: paths.notImplementedYet,
  component: NotImplementedYetComponent,
//    canActivate: [CanActivateService],
},
{
  path: paths.notFound,         // KF20210404 - Megnézni
  component: NotFoundComponent,
  canActivate: [],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonsRoutingModule { }
