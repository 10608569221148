import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "highlight"
})

export class HighlightSearch implements PipeTransform {

  transform(value: any, args: any, caseSens: boolean, allWords: boolean): any {
    if (!args) { return value; }
    if (allWords === false) {
      const array = args.split(" ");
      array.forEach(element => {
        const word = new RegExp(element, caseSens === true ? "g" : "gi");
        value = value.replace(word, "<mark><strong>$&</strong></mark>");
      });
      return value;
    } else {
      const re = new RegExp(args, caseSens === true ? "g" : "gi");
      return value.replace(re, "<mark><strong>$&</strong></mark>");
    }
  }
}
