import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class QuickMenuService {
  static permCanBrowseUsersWorktime: boolean;
  static timesheetMenu: boolean;
  static quickNoteMenu: boolean;
  static demandHomePageMenu: boolean;
  static newDemandMenu: boolean;
  static modifiedDemandsMenu: boolean;
  static usersMaintenanceMenu: boolean;
  static clientsMaintenanceMenu: boolean;
  static translationsMenu: boolean;
  static attachedDocumentsMenu: boolean;
  static documentationsMenu: boolean;
  static sysAdminMenu: boolean;
  static statisticsMenu: boolean;
  static officesMenu: boolean;
  static isoMenu: boolean;

  constructor() {}
  get permCanBrowseUsersWorktime() { return QuickMenuService.permCanBrowseUsersWorktime; }
  get timesheetMenu() { return QuickMenuService.timesheetMenu; }
  get quickNoteMenu() { return QuickMenuService.quickNoteMenu; }
  get demandHomePageMenu() { return QuickMenuService.demandHomePageMenu; }
  get newDemandMenu() { return QuickMenuService.newDemandMenu; }
  get modifiedDemandsMenu() { return QuickMenuService.modifiedDemandsMenu; }
  get usersMaintenanceMenu() { return QuickMenuService.usersMaintenanceMenu; }
  get clientsMaintenanceMenu() { return QuickMenuService.clientsMaintenanceMenu; }
  get translationsMenu() { return QuickMenuService.translationsMenu; }
  get attachedDocumentsMenu() { return QuickMenuService.attachedDocumentsMenu; }
  get documentationsMenu() { return QuickMenuService.documentationsMenu; }
  get sysAdminMenu() { return QuickMenuService.sysAdminMenu; }
  get statisticsMenu() { return QuickMenuService.statisticsMenu; }
  get officesMenu() { return QuickMenuService.officesMenu; }
  get isoMenu() { return QuickMenuService.isoMenu; }
}
