import { isArray, isNull } from 'util';

export const asyncEvery = async (arr, predicate) => {
   for (const e of arr) {
      if (!(await predicate(e))) {
         return false;
      }
   }
   return true;
};

export const asyncSome = async (arr, predicate) => {
   for (const e of arr) {
      if (await predicate(e)) {
         return true;
      }
   }
   return false;
};

export function checkIfValueChanged<T>(oldValue?: T, newValue?: T): T | undefined {
   oldValue = isNull(oldValue) ? undefined : oldValue; //TODO after typescript vrsn update should be ??
   newValue = isNull(newValue) ? undefined : newValue; //TODO after typescript vrsn update should be ??

   if (oldValue && isArray(oldValue)) {
      return JSON.stringify(oldValue) === JSON.stringify(newValue) ? undefined : newValue;
   }

   return oldValue === newValue ? undefined : newValue;
}
