import { Injectable } from "@angular/core";
import { OwnHolidays } from "@entities/api/holidays.interface";
import { UserlistGetResponse } from "@entities/api/userlist.interfaces";

@Injectable({
  providedIn: "root"
})
export class OwnHolidaysService {
  static defaultHolidays: OwnHolidays = [];
  static colleagues: UserlistGetResponse = [];

  constructor() {}
  get defaultHolidays() { return OwnHolidaysService.defaultHolidays; }
  get colleagues() { return OwnHolidaysService.colleagues; }
}
