import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { paths } from '@config';
import { hasPermission } from '@commons/functions/has-permission';
import { asyncSome } from '@commons/functions/utils';
import { UserDetailsService } from '@commons/services/user-details/user-details.service';

@Injectable({
   providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
   constructor(private router: Router) {}

   async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      if (!(UserDetailsService.loggedIn || UserDetailsService.forgottenPassword)) {
         this.router.navigate([paths.login], {
            queryParams: { returnUrl: state.url },
         });
         return false;
      }
      let activate = false;
      if (route.data && route.data.secured) {
         const perms = route.data.secured as string[];
         activate = await asyncSome(perms, hasPermission);
      } else {
         // Ha nincs megadva a jogosultság, viszont rátettük ezt a Guardot, akkor nem érhetjük el
      }

      // TODO nincs jogosultságod képernyő
      if (!activate) {
         this.router.navigate([paths.notFound]);
      } //TODO ezt nem így kellene megoldani
      return activate;
   }
}
