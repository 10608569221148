import { Component, ElementRef, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { User } from '../userlist-selector/userlist-selector.component';
import {
   MatAutocomplete,
   MatAutocompleteSelectedEvent,
   MatChipInputEvent,
   MatChipList,
   MatFormFieldControl,
} from '@angular/material';
import { ENTER } from '@angular/cdk/keycodes';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { map } from 'rxjs/operators';

@Component({
   selector: 'user-chip-selector',
   templateUrl: './user-chip-selector.component.html',
   styleUrls: ['./user-chip-selector.component.scss'],
   providers: [{ provide: MatFormFieldControl, useExisting: UserChipSelectorComponent }],
   host: {
      '[id]': 'id',
      '[attr.aria-describedby]': 'describedBy',
   },
})
export class UserChipSelectorComponent implements OnInit, ControlValueAccessor {
   static nextId = 0;

   private _placeholder: string;
   private _required = false;
   private _disabled = false;

   id = `user-chip-selector-${UserChipSelectorComponent.nextId++}`;
   controlType = 'user-chip-selector';

   visible = true;
   selectable = true;
   removable = true;
   addOnBlur = true;
   focused = false;
   touched = false;

   separatorKeysCodes: number[] = [ENTER];
   userCtrl = new FormControl();
   users: User[] = [];
   filteredUsers: Observable<User[]>;
   stateChanges = new Subject<void>();
   onChange = (_: any) => {};
   onTouched = () => {};

   @ViewChild('userInput', { static: false }) userInput: ElementRef<HTMLInputElement>;
   @ViewChild('chipList', { static: false }) matChipList: MatChipList;
   @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
   @Input() allUsers: User[] = [];

   get errorState(): boolean {
      if (this.ngControl) {
         return this.ngControl.touched && this.required && this.empty;
      }
      return this.touched && this.required && this.empty;
   }

   @Input()
   get disabled(): boolean {
      return this._disabled;
   }
   set disabled(value: boolean) {
      this._disabled = coerceBooleanProperty(value);
      this.stateChanges.next();
   }

   @Input()
   get required(): boolean {
      return this._required;
   }
   set required(value: boolean) {
      this._required = coerceBooleanProperty(value);
      this.stateChanges.next();
   }

   get empty() {
      return this.users.length === 0;
   }

   @Input()
   get placeholder(): string {
      return this._placeholder;
   }
   set placeholder(value: string) {
      this._placeholder = value;
      this.stateChanges.next();
   }

   @Input()
   get value(): User[] | null {
      return this.users;
   }
   set value(users: User[] | null) {
      this.users = users || [];
      this.stateChanges.next();
   }

   get shouldLabelFloat() {
      if (this.matChipList) {
         return this.matChipList.focused || !this.empty;
      }
      return !this.empty;
   }

   constructor(@Optional() @Self() public ngControl: NgControl) {
      if (this.ngControl != null) {
         this.ngControl.valueAccessor = this;
      }
      this.filteredUsers = this.userCtrl.valueChanges.pipe(map((username: string | null) => this._filter(username)));
   }

   ngOnInit(): void {}

   ngAfterViewInit(): void {
      this.matChipList.registerOnTouched(() => {
         if (this.ngControl) {
            this.ngControl.control.markAsTouched();
         }
         this.touched = true;
         this.stateChanges.next();
      });
   }

   remove(username: string): void {
      const index = this.users.findIndex((l) => l.username === username);
      if (index >= 0) {
         this.users.splice(index, 1);
      }
   }

   selected(event: MatAutocompleteSelectedEvent): void {
      this.users.push(event.option.value);
      this.userInput.nativeElement.value = '';
      this.userCtrl.setValue(null);
   }

   private _filter(value: string | User): User[] {
      //we want to filter all previously added label to prevent duplications
      let filtered = this.allUsers.filter((al) => !this.users.some((l) => l.username === al.username));

      //if value is null
      if (!value) {
         return filtered;
      }

      // if the value is not a string, meaning a user just added to the field
      // in this case we want to return the filtered list without this item
      if (typeof value !== 'string') {
         return filtered.filter((r) => r.username !== value.username);
      }

      let filterValue = value.toLowerCase();
      filtered = filtered.filter((user) => {
         return (user.firstname + user.lastname).toLowerCase().indexOf(filterValue) > 0;
      });
      return filtered;
   }

   writeValue(users: User[] | null): void {
      this.value = users;
   }

   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   ngOnDestroy() {
      this.stateChanges.complete();
   }

   setDescribedByIds(ids: string[]) {}

   onContainerClick(event: MouseEvent) {}
}
