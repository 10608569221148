import { UploadFileService } from '../../services';
import { Component, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
   selector: 'app-form-upload',
   templateUrl: './form-upload.component.html',
   styleUrls: ['./form-upload.component.scss'],
})
export class FormUploadComponent implements OnInit {
   selectedFiles: FileList;
   currentFileUpload: File;
   progress: { percentage: number } = { percentage: 0 };

   constructor(private uploadService: UploadFileService) {}

   ngOnInit() {}

   selectFile(event) {
      this.selectedFiles = event.target.files;
   }

   async upload() {
      this.progress.percentage = 0;

      this.currentFileUpload = this.selectedFiles.item(0);
      (await this.uploadService.pushFileToStorage(this.currentFileUpload)).subscribe((event) => {
         if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round((100 * event.loaded) / event.total);
         } else if (event instanceof HttpResponse) {
            //TODO értesítés a felhasználónak
         }
      });

      this.selectedFiles = undefined;
   }
}
