import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@ha8rt/http.service';
import { routes, paths } from '@config';
import { UserlistGetResponse } from '@entities/api/userlist.interfaces';
import { PartnerlistGetResponse } from '@entities/api/partnerlist.interface';

@Injectable({
   providedIn: 'root',
})
export class PartnerUsersService {
   private partnersUsersListService: HttpService;

   constructor(private http: HttpClient) {
      this.partnersUsersListService = new HttpService(this.http).setApi(routes.partnersUsersList);
   }

   async getPartnerUsersList(partnerName: string, colleague: boolean, plusUsers?: boolean) {
      return this.partnersUsersListService
         .get<UserlistGetResponse>({ partnerName: partnerName, colleague: false, plusUsers })
         .toPromise()
         .then((r) => r.body);
      //TODO error handling
   }
}
