import { Component, OnInit } from "@angular/core";
import { UserDetailsService, SetTitleService } from "@commons/services";
import { szegedswLogo } from "@config";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  logo = szegedswLogo;
  get database() { return UserDetailsService.database; }
  get mobile() { return UserDetailsService.mobile; }
  get colleague() { return UserDetailsService.isWorker; }
  get language() { return UserDetailsService.userLang; }

  constructor(private title: SetTitleService) {}

  ngOnInit() {
    this.title.setTitle("Home page");
  }
}
