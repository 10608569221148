import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@ha8rt/http.service';
import { routes, paths } from '@config';
import { PartnerlistGetResponse } from '@entities/api/partnerlist.interface';
import { AllPartnersGetResponse, PartnersPatchRequest, PartnersPostRequest } from '@entities/api/partners.interface';

@Injectable({
   providedIn: 'root',
})
export class PartnerService {
   private partnerListService: HttpService;
   private partnerService: HttpService;

   constructor(private http: HttpClient) {
      this.partnerListService = new HttpService(this.http).setApi(routes.partnerlist);
      this.partnerService = new HttpService(this.http).setApi(routes.partners);
   }

   public async getPartnerList() {
      return this.partnerListService
         .get<PartnerlistGetResponse>(undefined)
         .toPromise()
         .then((r) => r.body);
      //TODO error handling
   }

   public async createNewPartner(data: PartnersPostRequest) {
      return this.partnerService.post<unknown>(data).toPromise();
   }

   public async updatePartner(data: PartnersPatchRequest) {
      return this.partnerService.patch<unknown>('update', data).toPromise();
   }

   public async togglePartnerActiveState(data: { partnerId: number; active: boolean }) {
      return this.partnerService.put<unknown>(data).toPromise();
   }

   public async getAllPartner() {
      return this.partnerService.get<AllPartnersGetResponse>(undefined).toPromise();
   }
}
