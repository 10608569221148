import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@ha8rt/http.service';
import { routes, paths } from '@config';
import { UserlistGetResponse } from '@entities/api/userlist.interfaces';
import { DictionaryQueryGetResult } from '@entities/api/dictionary-query.interfaces';
const urgencyGroupName = 'demand-urgency';
const stateGroupName = 'demand-state';
const kindGroupName = 'demand-kind';
const statusGroupName = 'demand-status';
const typeGroupName = 'demand-type';
const accountingGroupName = 'demand-accounting';

export type DictionaryKeys =
   | 'demand-urgency'
   | 'demand-state'
   | 'demand-kind'
   | 'demand-status'
   | 'demand-type'
   | 'demand-accounting'
   | 'partner-labels';
@Injectable({
   providedIn: 'root',
})
export class DictionaryService {
   private httpService: HttpService;

   constructor(private http: HttpClient) {
      this.httpService = new HttpService(this.http).setApi(routes.dictionaryQuery);
   }

   async getDictionaryValues(groupname: DictionaryKeys) {
      return this.httpService
         .get<DictionaryQueryGetResult>({ groupname })
         .toPromise()
         .then((r) => r.body);
      //TODO error handling
   }
}
