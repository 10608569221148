import { NgModule } from '@angular/core';
import { CommonsRoutingModule } from './commons-routing.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { MysnackbarComponent } from './components/mysnackbar/mysnackbar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotImplementedYetComponent } from './components/not-implemented-yet/not-implemented-yet.component';
import { SecuredDirective } from './directives';
import { FormUploadComponent } from './components/form-upload/form-upload.component';
import { MyCountdownComponent } from './components/my-countdown/my-countdown.component';
import { QuickNoteDialogComponent } from './components/quick-note-dialog/quick-note-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { UserlistSelectorComponent } from './components/userlist-selector/userlist-selector.component';
import { EmailChipSelectorComponent } from './components/email-chip-selector/email-chip-selector.component';
import { LabelSelectorComponent } from './components/label-selector/label-selector.component';
import { UserChipSelectorComponent } from './components/user-chip-selector/user-chip-selector.component';

@NgModule({
   declarations: [
      DialogComponent,
      MysnackbarComponent,
      NotFoundComponent,
      NotImplementedYetComponent,
      SecuredDirective,
      FormUploadComponent,
      MyCountdownComponent,
      QuickNoteDialogComponent,
      UserlistSelectorComponent,
      EmailChipSelectorComponent,
      LabelSelectorComponent,
      UserChipSelectorComponent,
   ],
   imports: [SharedModule, CommonsRoutingModule],
   exports: [
      DialogComponent,
      MysnackbarComponent,
      NotFoundComponent,
      NotImplementedYetComponent,
      SecuredDirective,
      FormUploadComponent,
      MyCountdownComponent,
      QuickNoteDialogComponent,
      UserlistSelectorComponent,
      EmailChipSelectorComponent,
      LabelSelectorComponent,
      UserChipSelectorComponent
   ],
   entryComponents: [MysnackbarComponent],
})
export class CommonsModule {}
