import { HttpService, httpCodes } from "@ha8rt/http.service";

export let config: { [key: string]: string };

export async function translationQuery(
  lang: string,
  service: HttpService
): Promise<void> {
  return new Promise((resolve, reject) => {
    const selectedLanguage = { language: lang };
    service.get<{ [key: string]: string }>(selectedLanguage, (res) => {
      if (res.status === httpCodes.created && res.body) {
        localStorage.setItem("translation", JSON.stringify(res.body));
        resolve();
      }
      reject();
    });
  });
}
