import { UserDetailsService, SetTitleService, SnackbarService } from "@commons/services";
import { PasswordResetPostRequest } from "@entities/api/password-reset.interfaces";
import { Router, ActivatedRoute } from "@angular/router";
import { routes, szegedswLogo, paths } from "@config";
import { HttpClient } from "@angular/common/http";
import { httpCodes, HttpService, Redirect } from "@ha8rt/http.service";
import { NgForm } from "@angular/forms";
import { ModalHandler } from "@ha8rt/modal";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-forgotten-password",
  templateUrl: "./forgotten-password.component.html",
  styleUrls: ["./forgotten-password.component.scss"],
})
export class ForgottenPasswordComponent implements OnInit {
  logo = szegedswLogo;
  service: HttpService;
  modal = new ModalHandler();
  redirect: Redirect;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private title: SetTitleService
  ) {
    this.service = new HttpService(this.http).setApi(routes.passwordReset);
    this.redirect = new Redirect(this.router, this.route);
  }

  ngOnInit() {
    this.title.setTitle("Forgotten password");
  }

  generatePasswordSubmit(form: NgForm) {
    const credential = {
      username: form.value.username,
      email: form.value.email,
    };
    this.service.post<PasswordResetPostRequest>(credential, async (res) => {
      if (res.status === httpCodes.created) {
        this.snackbar.openAlertMsg(
          `Please change the password you received in the email!`,
          "yellow",
          6000,
        );
        UserDetailsService.forgottenPassword = false;
        this.router.navigate([paths.changePassword]);
        this.redirect.setDefaultUrl(paths.changePassword);
        localStorage.removeItem("urlAfterRedirects");
      } else if (res.status === httpCodes.preconditionRequired) {
        this.snackbar.openAlertMsg(
          `Wrong username-email pair!`,
          "yellow",
          6000,
        );
      }
    });
  }

  goToLogin() {
    UserDetailsService.forgottenPassword = false;
    this.router.navigate([paths.login]);
    this.redirect.setDefaultUrl(paths.login);
    localStorage.removeItem("urlAfterRedirects");
  }
}
