import { Component, Input, OnInit } from '@angular/core';
import { User } from '@commons/components/userlist-selector/userlist-selector.component';
import { DemandService, WipDemand } from '../services/demand.service';
import { DictionaryService } from '../services/dictionary.service';
import { DictionaryQueryGetResult } from '@entities/api/dictionary-query.interfaces';


@Component({
  selector: 'wip-demand-list',
  templateUrl: './wip-demand-list.component.html',
  styleUrls: ['./wip-demand-list.component.scss']
})
export class WipDemandListComponent implements OnInit {

  @Input('users')
  users :User[]= [];

  @Input('selectedUsers')
  selectedUsers : User[] = [];

  @Input('demands')
  set demands(demands: WipDemand[]) {
    this.initDatasources();
    demands.map( (d) => {
      this.dataSources[d.status || this.UNKNOWN_STATUS].push(d);
    })
  }



  dataSources: {[key in string]: WipDemand[]} = {}

  possibleDemandStates: DictionaryQueryGetResult = [];

  displayedColumns: string[] = ['demandLabel', 'demandName', 'arrangers', 'responsible'];

  readonly UNKNOWN_STATUS = 'Unknown status';

  constructor( private demandService: DemandService, private dictionaryService: DictionaryService) {
  }

  ngOnInit() {
    this.initDatasources();
    this.initData();

  }

  async initData() {
    await Promise.all([this.loadDemandStates()]);

  }

  async loadDemandStates() {
    this.possibleDemandStates = (await this.dictionaryService.getDictionaryValues('demand-status'));
    this.possibleDemandStates.push({
      dictId:-1,
      abbreviation:'UNKNWN',
      dictionaryname: this.UNKNOWN_STATUS,
    });    
    this.initDatasources();
  }

  initDatasources() {
    this.possibleDemandStates.forEach(ds => {
      this.dataSources[ds.dictionaryname] =[];
    });
  }


  getArrangersAsString(wd: WipDemand) {
    return wd.arrangers.map(u=> u.lastname + ' ' + u.firstname).join(', ');
  }

  getResponsibleAsString(wd: WipDemand) {
    return wd.responsible.lastname + ' ' + wd.responsible.firstname;
  }
}
