// tslint:disable:variable-name
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ENTER } from "@angular/cdk/keycodes";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, NgControl, FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatChipList } from "@angular/material";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

export interface User {
  username: string;
  firstname: string;
  lastname: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "userlist-selector",
  templateUrl: "userlist-selector.component.html",
  styleUrls: ["userlist-selector.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: UserlistSelectorComponent },
  ],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    "[id]": "id",
    "[attr.aria-describedby]": "describedBy",
  },
})
export class UserlistSelectorComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<User[]>,
    OnDestroy,
    AfterViewInit {
  static nextId = 0;

  @ViewChild("userInput", { static: false }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild("matChipList", { static: false }) matChipList: MatChipList;
  @Input() allUsers: User[] = [];

  private _removable = true;
  private _selectable = false;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  users: User[] = [];

  id = `userlist-selector-${UserlistSelectorComponent.nextId++}`;
  controlType = "userlist-selector";
  userCtrl = new FormControl();
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  filteredUsers: Observable<User[]>;
  separatorKeysCodes: number[] = [ENTER];

  get errorState(): boolean {
    if (this.ngControl) {
      return this.ngControl.touched && this.required && this.empty;
    }
    return this.touched && this.required && this.empty;
  }
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    return this.users.length === 0;
  }

  get shouldLabelFloat() {
    if (this.matChipList) {
      return this.matChipList.focused || !this.empty;
    }
    return !this.empty;
  }

  @Input()
  get removable(): boolean {
    return this._removable;
  }
  set removable(value: boolean) {
    this._removable = value;
    this.stateChanges.next();
  }

  @Input()
  get selectable(): boolean {
    return this._selectable;
  }
  set selectable(value: boolean) {
    this._selectable = value;
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): User[] | null {
    return this.users;
  }
  set value(users: User[] | null) {
    this.users = users || [];
    this.stateChanges.next();
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      map((user: string | User | undefined) => {
        if (!user) {
          return this._filter("");
        } else if (typeof user === "string") {
          return this._filter(user);
        } else {
          return [user];
        }
      })
    );
  }
  ngAfterViewInit(): void {
    this.matChipList.registerOnTouched(() => {
      if (this.ngControl) {
        this.ngControl.control.markAsTouched();
      }
      this.touched = true;
      this.stateChanges.next();
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {}

  onContainerClick(event: MouseEvent) {}

  writeValue(users: User[] | null): void {
    this.value = users;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.users);
  }

  remove(u: User): void {
    const index = this.users.findIndex((u2) => {
      return (
        u.username === u2.username &&
        u.firstname === u2.firstname &&
        u.lastname === u2.lastname
      );
    });

    if (index >= 0) {
      this.users.splice(index, 1);
      this.userInput.nativeElement.value = "";
      this.userCtrl.setValue("");
      this._handleInput();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const toInsert = event.option.value;
    if (!this.isUserSelected(toInsert)) {
      this.users.push(toInsert);
      this.userInput.nativeElement.value = "";
      this.userCtrl.setValue(null);
      this._handleInput();
    }
  }
  userString(u: User): string {
    return `${u.lastname} ${u.firstname} (${u.username})`;
  }
  private isUserSelected(u: User): boolean {
    return this.users.some((u2) => {
      return (
        u.username === u2.username &&
        u.firstname === u2.firstname &&
        u.lastname === u2.lastname
      );
    });
  }

  private _filter(value: string): User[] {
    const all = this.allUsers || [];
    const filterValue = value
      .toLowerCase()
      .split(" ")
      .filter((st) => {
        return st !== "";
      });
    if (filterValue.length === 0) {
      return all.filter((user) => {
        return !this.isUserSelected(user);
      });
    }
    return all
      .filter((user) => {
        if (this.isUserSelected(user)) {
          return false;
        }
        return filterValue.some((fv) => {
          return (
            user.username.toLowerCase().indexOf(fv) !== -1 ||
            user.firstname.toLowerCase().indexOf(fv) !== -1 ||
            user.lastname.toLowerCase().indexOf(fv) !== -1
          );
        });
      })
      .sort((a, b) => {
        function rank(u: User, filter: string) {
          let r = Number.MAX_SAFE_INTEGER;
          function updateR(s: string) {
            const ind = s.toLowerCase().indexOf(filter);
            if (ind !== -1) {
              r = Math.min(r, ind);
            }
          }
          updateR(u.firstname);
          updateR(u.lastname);
          updateR(u.username);
          r = -r;
          if (u.firstname.toLowerCase() === filter) {
            r = 1;
          }
          if (u.lastname.toLowerCase() === filter) {
            r = 2;
          }
          if (u.username.toLowerCase() === filter) {
            r = 3;
          }
          return r;
        }
        function sumRank(u: User) {
          return filterValue.reduce((acc, str) => {
            return acc + rank(u, str);
          }, 0);
        }
        return sumRank(b) - sumRank(a);
      });
  }
}
