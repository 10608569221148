import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER } from '@angular/cdk/keycodes';
import {
   AfterViewInit,
   Component,
   ElementRef,
   Input,
   OnDestroy,
   OnInit,
   Optional,
   Self,
   ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatChipList } from '@angular/material';
import { MatFormFieldControl } from '@angular/material/form-field';
import { DictionaryQueryGetResult } from '@entities/api/dictionary-query.interfaces';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DictionaryKeys, DictionaryService } from 'src/app/services/dictionary.service';

@Component({
   selector: 'label-selector',
   templateUrl: './label-selector.component.html',
   styleUrls: ['./label-selector.component.scss'],
   providers: [{ provide: MatFormFieldControl, useExisting: LabelSelectorComponent }],
   host: {
      '[id]': 'id',
      '[attr.aria-describedby]': 'describedBy',
   },
})
// OnDestroy,
// AfterViewInit
export class LabelSelectorComponent
   implements OnInit, ControlValueAccessor, MatFormFieldControl<DictionaryQueryGetResult>
{
   static nextId = 0;

   private _placeholder: string;
   private _required = false;
   private _disabled = false;

   id = `label-selector-${LabelSelectorComponent.nextId++}`;
   controlType = 'label-selector';

   visible = true;
   selectable = true;
   removable = true;
   addOnBlur = true;
   focused = false;
   touched = false;

   separatorKeysCodes: number[] = [ENTER];
   labelCtrl = new FormControl();
   filteredFruits: Observable<string[]>;
   labels: DictionaryQueryGetResult = [];
   filteredLabels: Observable<DictionaryQueryGetResult>;
   stateChanges = new Subject<void>();
   onChange = (_: any) => {};
   onTouched = () => {};

   @ViewChild('labelInput', { static: false }) labelInput: ElementRef<HTMLInputElement>;
   @ViewChild('chipList', { static: false }) matChipList: MatChipList;
   @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
   @Input() allLabels: DictionaryQueryGetResult;

   get errorState(): boolean {
      if (this.ngControl) {
         return this.ngControl.touched && this.required && this.empty;
      }
      return this.touched && this.required && this.empty;
   }

   @Input()
   get disabled(): boolean {
      return this._disabled;
   }
   set disabled(value: boolean) {
      this._disabled = coerceBooleanProperty(value);
      this.stateChanges.next();
   }

   @Input()
   get required(): boolean {
      return this._required;
   }
   set required(value: boolean) {
      this._required = coerceBooleanProperty(value);
      this.stateChanges.next();
   }

   get empty() {
      return this.labels.length === 0;
   }

   @Input()
   get placeholder(): string {
      return this._placeholder;
   }
   set placeholder(value: string) {
      this._placeholder = value;
      this.stateChanges.next();
   }

   @Input()
   get value(): DictionaryQueryGetResult | null {
      return this.labels;
   }
   set value(users: DictionaryQueryGetResult | null) {
      this.labels = users || [];
      this.stateChanges.next();
   }

   get shouldLabelFloat() {
      if (this.matChipList) {
         return this.matChipList.focused || !this.empty;
      }
      return !this.empty;
   }

   constructor(@Optional() @Self() public ngControl: NgControl, private dictionaryService: DictionaryService) {
      if (this.ngControl != null) {
         this.ngControl.valueAccessor = this;
      }
      this.filteredLabels = this.labelCtrl.valueChanges.pipe(map((label: string | null) => this._filter(label)));
   }

   ngOnInit(): void {}

   ngAfterViewInit(): void {
      this.matChipList.registerOnTouched(() => {
         if (this.ngControl) {
            this.ngControl.control.markAsTouched();
         }
         this.touched = true;
         this.stateChanges.next();
      });
   }

   add(event: MatChipInputEvent): void {
      // Add fruit only when MatAutocomplete is not open
      // To make sure this does not conflict with OptionSelected Event
      if (!this.matAutocomplete.isOpen) {
         const input = event.input;
         const value = event.value;

         if ((value || '').trim()) {
            this.labels.push();
         }

         if (input) {
            input.value = '';
         }

         this.labelCtrl.setValue(null);
      }
   }

   remove(label: string): void {
      const index = this.labels.findIndex((l) => l.abbreviation === label);
      if (index >= 0) {
         this.labels.splice(index, 1);
      }
   }

   selected(event: MatAutocompleteSelectedEvent): void {
      this.labels.push(event.option.value);
      this.labelInput.nativeElement.value = '';
      this.labelCtrl.setValue(null);
   }

   private _filter(
      value: string | { dictId: number; dictionaryname: string; abbreviation: string },
   ): DictionaryQueryGetResult {
      //we want to filter all previously added label to prevent duplications
      let filtered = this.allLabels.filter((al) => !this.labels.some((l) => l.dictId === al.dictId));

      //if value is null
      if (!value) {
         return filtered;
      }

      // if the value is not a string, meaning a lebel just added to the field
      // in this case we want to return the filtered list without this item
      if (typeof value !== 'string') {
         return filtered.filter((r) => r.dictId !== value.dictId);
      }

      let filterValue = value.toLowerCase();
      filtered = filtered.filter((label) => {
         return label.dictionaryname.toLowerCase().indexOf(filterValue) === 0;
      });
      return filtered;
   }

   writeValue(labels: DictionaryQueryGetResult | null): void {
      this.value = labels;
   }

   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   ngOnDestroy() {
      this.stateChanges.complete();
   }

   setDescribedByIds(ids: string[]) {}

   onContainerClick(event: MouseEvent) {}
}
