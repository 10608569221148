import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, ViewChild, Input, Optional, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatChipInputEvent, MatFormFieldControl } from "@angular/material";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "email-chip-selector",
  templateUrl: "email-chip-selector.component.html",
  styleUrls: ["email-chip-selector.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: EmailChipSelectorComponent },
  ],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    "[id]": "id",
    "[attr.aria-describedby]": "describedBy",
  },
})

export class EmailChipSelectorComponent {
  static nextId = 0;
  // tslint:disable-next-line:variable-name
  private _disabled = false;

  @ViewChild("emailInput", { static: false }) emailInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;
  @Input() allEmails: string[] = [];

  @Input()
  get value(): string[] | null {
    return this.emails;
  }
  set value(emails: string[] | null) {
    this.emails = emails || [];
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailCtrl = new FormControl();
  filteredEmails: Observable<string[]>;
  emails: string[] = [];
  stateChanges = new Subject<void>();

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.filteredEmails = this.emailCtrl.valueChanges.pipe(
        startWith(null),
        map((email: string | null) => email ? this._filter(email) : this.allEmails.slice()));
  }

  setDescribedByIds(ids: string[]) {}

  onContainerClick(event: MouseEvent) {}

  writeValue(emails: string[] | null): void {
    this.value = emails;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  userString(e: string[]): string {
    return `${e}`;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.emails.push(value);
    }
    // tslint:disable-next-line:no-non-null-assertion
    // event.input!.remove();
    this.emailCtrl.setValue(null);
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue);
    this.emailInput.nativeElement.value = "";
    this.emailCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allEmails.filter(email => email.toLowerCase().indexOf(filterValue) !== -1);
  }
}
