import { DialogButtonClass } from "../../functions/dialog.data.binder";
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface IDialogData {
  header?: string;
  headerParameters?: object;
  content?: string;
  contentParameters?: object;
  firstButtonObj?: DialogButtonClass;
  secondButtonObj?: DialogButtonClass;
  thirdButtonObj?: DialogButtonClass;
}

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
  static readonly textColor = "black";
  static readonly buttonTextColor = "white";
  static readonly buttonBgColor = "#10498c";

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData
  ) {}

  ngOnInit() {}
}
