import { HttpService, httpCodes } from "@ha8rt/http.service";
import {
  DictionaryQueryGetRequest,
  DictionaryQueryGetResult,
} from "@entities/api/dictionary-query.interfaces";

export type DictionaryEntry = DictionaryQueryGetResult[number];

export function dictionaryQuery(
  groupname: string,
  results: DictionaryEntry[],
  service: HttpService
) {
  const request: DictionaryQueryGetRequest = { groupname };
  service.get<DictionaryQueryGetResult>(request, (res) => {
    if (res.status === httpCodes.created) {
      results.push(...res.body);
    }
  });
}
