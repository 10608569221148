import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material";

export interface SnackBarContent {
  msg: string;
  color: string;
  textAlign: string;
}

@Component({
  selector: "app-mysnackbar",
  templateUrl: "./mysnackbar.component.html",
  styleUrls: ["./mysnackbar.component.scss"],
})
export class MysnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarContent) {}
}
