import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class UploadFileService {
  constructor(private http: HttpClient) { }

  // tslint:disable-next-line:max-line-length
  async pushFileToStorage(file: File, demandLabel?: string, noticeNumber?: number, documentationId?: number): Promise<Observable<HttpEvent<{}>>> {
    const formdata: FormData = new FormData();
    formdata.append("file", file);
    let url: string;
    url = "/api/upload";
    if (demandLabel && demandLabel.length > 0) {
      url += `?demandLabel=${demandLabel}`;
      if (noticeNumber && noticeNumber !== 0) {
        url += `&noticeNumber=${noticeNumber}`;
      }
    } else if (documentationId && documentationId !== 0) {
      url = `/api/documentationupload?documentationId=${documentationId}`;
    }
    const req = new HttpRequest("POST", url, formdata, {
      reportProgress: true,
      responseType: "text"
    });
    return this.http.request(req);
  }

  // tslint:disable-next-line:max-line-length
  async pushISOFileToStorage(file: File, isoId: number): Promise<Observable<HttpEvent<{}>>> {
    const formdata: FormData = new FormData();
    formdata.append("file", file);
    let url: string;
    url = `/api/isoupload?isoId=${isoId}`;
    const req = new HttpRequest("POST", url, formdata, {
      reportProgress: true,
      responseType: "text"
    });
    return this.http.request(req);
  }
}
