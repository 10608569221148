export enum Permission {
  funcBookmarks                       = "FUNC_BOOKMARKS",

  funcUserMaintenance                 = "FUNC_USER_MAINTENANCE",
  funcClientMaintenance               = "FUNC_CLIENT_MAINTENANCE",
  funcTranslations                    = "FUNC_TRANSLATIONS",
  funcAttachedDocuments               = "FUNC_ATTACHED_DOCUMENTS",
  funcDocumentations                  = "FUNC_DOCUMENTATIONS",
  funcModifiedDemands                 = "FUNC_MODIFIED_DEMANDS",
  funcUsersGuide                      = "FUNC_USERS_GUIDE",
  funcWipDemandsResponsible           = "FUNC_WIP_DEMANDS_RESPONSIBLE",
  funcWipDemandsArranger              = "FUNC_WIP_DEMANDS_ARRANGER",


  // iso
  funcIsoDocumentations               = "FUNC_ISO_DOCUMENTATIONS",
  funcIsoMaintenance                  = "FUNC_ISO_MAINTENANCE",
  // worktime
  funcTimesheet                       = "FUNC_TIMESHEET",
  funcQuickNote                       = "FUNC_QUICK_NOTE",

  // demand
  funcDemandHomePage                  = "FUNC_DEMAND_HOME_PAGE",
  funcNewDemand                       = "FUNC_NEW_DEMAND",

  // menuStatistics                      = "MENU_STATISTICS",
  funcStatisticsWorkTime              = "FUNC_STATISTICS_WORK_TIME",
  funcStatisticsByUsers               = "FUNC_STATISTICS_BY_USERS",
  funcStatisticsByClients             = "FUNC_STATISTICS_BY_CLIENTS",
  funcStatisticsByOther               = "FUNC_STATISTICS_BY_OTHER",
  funcStatisticsByDemand              = "FUNC_STATISTICS_BY_DEMAND",
  funcStatisticsWorkDayByType         = "FUNC_STATISTICS_WORKDAYBYTYPE",
  funcStatisticsReserved              = "FUNC_STATISTICS_RESERVED",
  // menuOffices                         = "MENU_OFFICES",
  funcOfficesRegistry                 = "FUNC_OFFICES_REGISTRY",
  funcOfficesIncomingInvoices         = "FUNC_OFFICES_INCOMING_INVOICES",
  funcOfficesOutgoingInvoices         = "FUNC_OFFICES_OUTGOING_INVOICES",
  funcOfficesHolidays                 = "FUNC_OFFICES_HOLIDAYS",
  funcOfficesVacations                = "FUNC_OFFICES_VACATIONS",
  // sysadmin
  funcSysAdminDictionary              = "FUNC_SYS_ADMIN_DICTIONARY",
  funcSysAdminRoleGroups              = "FUNC_SYS_ADMIN_ROLE_GROUPS",
  funcSysAdminUsers                   = "FUNC_SYS_ADMIN_USERS",
  funcSysAdminUserRights              = "FUNC_SYS_ADMIN_USER_RIGHTS",
  funcSysAdminClients                 = "FUNC_SYS_ADMIN_CLIENTS",
  funcSysAdminPartnersUsers           = "FUNC_SYS_ADMIN_PARTNERS_USERS",
  funcSysAdminChangeTracking          = "FUNC_SYS_ADMIN_CHANGE_TRACKING",
  funcSettings                        = "FUNC_SETTINGS",
  permCanChangeNotice                 = "PERM_CAN_CHANGE_NOTICE",
  permDemandBrowse                    = "PERM_DEMAND_BROWSE",
  permDemandModify                    = "PERM_DEMAND_MODIFY",
  permCanBrowseUsersWorktime          = "PERM_CAN_BROWSE_USERS_WORKTIME",
  permCanTranslate                    = "PERM_CAN_TRANSLATE",
}

export const roles = {
  sysadmin : { permissions : [Permission.funcSysAdminClients, Permission.funcSysAdminDictionary, Permission.funcSysAdminRoleGroups, Permission.funcSysAdminUserRights, Permission.funcSysAdminUsers] },
  offices : { permissions : [Permission.funcOfficesRegistry, Permission.funcOfficesHolidays, Permission.funcOfficesIncomingInvoices, Permission.funcOfficesOutgoingInvoices, Permission.funcOfficesVacations ]},
  statistics: { permissions : [Permission.funcStatisticsByClients, Permission.funcStatisticsByDemand,Permission.funcStatisticsByUsers, Permission.funcStatisticsReserved, Permission.funcStatisticsWorkTime]},
  demand: { permissions: [Permission.funcDemandHomePage, Permission.funcNewDemand, Permission.permDemandBrowse, Permission.permCanChangeNotice]},
  iso: { permissions: [Permission.funcIsoDocumentations, Permission.funcIsoMaintenance]},
  worktime: { permissions: [Permission.funcTimesheet, Permission.funcQuickNote]},
  clientOrganization: { permissions: [Permission.funcClientMaintenance, Permission.funcUserMaintenance]},
}
